import React from "react";

export const Map = ({className=""}) => (
    <svg className={className} viewBox="0 0 774 732" version="1.1" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5">
        <g id="Map" transform="matrix(1.07328,0,0,0.969033,403.836,826.25)">
            <g clip-path="url(#_clip1)">
                <g transform="matrix(0.931721,0,0,1.03196,-1184.45,-1963.43)">
                    <g id="map">
                        <g id="Seattle">
                            <path d="M1161.23,1343.4L1161.06,1283L1254.05,1289.08L1259.45,1287.5L1254.56,1386.71L1234.41,1386.16L1229.18,1392.12L1161.23,1343.4Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1126.71,1486.49L1244.07,1493.81L1244.76,1529.25L1219.39,1559.71L1177.2,1557.98L1126.88,1517.34L1126.71,1486.49Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M881.232,1306.32L908.658,1297.98L915.711,1289.97L926.039,1287.64L934.277,1272.3L946.48,1268.64L977.742,1251.35L987.953,1251.19L993.002,1262.96L992.172,1278.95L1013.97,1282.83L1060.21,1302.04L1075.96,1350.03L1069.39,1477.99L1056.66,1493.02L991.863,1463.39L951.271,1415.86L953.254,1383.35L947.702,1371.29L923.627,1336.07L881.232,1306.32Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1069.01,1304.39L1084.06,1350.54L1076.41,1476.08L1073.04,1484.63L1081.68,1485.78L1082.57,1478.67L1087.44,1478.67L1087.56,1517.77L1093.74,1517.88L1094.67,1478.36L1098.97,1477.89L1099.56,1517.01L1106.29,1518.23C1106.29,1518.23 1105.34,1497.5 1106.05,1490.89L1110.6,1490.59L1111.38,1513.38L1120.42,1514.3L1120.18,1486.87L1112.82,1481.63L1114.81,1332.98L1106.62,1331.48L1106.25,1339.97L1095.06,1338.55L1094.17,1323.08L1069.01,1304.39Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1261.45,1393.62L1267.26,1261.01L1285.92,1247.31L1330.02,1249.76L1328.3,1356.27L1314.25,1358.98L1291.78,1405.17L1283.52,1411.79L1268.02,1403.92L1261.45,1393.62Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1154.65,1336.02L1132.74,1317.17L1122.21,1315.94L1116.85,1312.66L1110.25,1314.75L1079.78,1295.12L1070.43,1281.19L1061.08,1279.67L1056.57,1283.32L1042.09,1285.35L1015.37,1273.06L1000.44,1258.52L989.815,1233.55L982.357,1222.89L980.222,1212.79L994.158,1194.27L1003.01,1162.17L1009.76,1155.59L1009.76,1139.88L1153.56,1140.26L1154.65,1336.02Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1160.59,1183.98L1243.98,1185.46L1245.71,1200.06L1236.5,1227.67L1249.39,1248.12L1259.05,1259.99L1259.64,1281.4L1161.69,1275.45L1160.59,1183.98Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1335.28,1355.68L1336.05,1246.53L1342.16,1236.1L1432.92,1275.46L1438.8,1295.94L1452.38,1307.52L1467.45,1311.14L1551.67,1291.73L1544.36,1335.75L1520.5,1361.26L1519.74,1387.54L1510.16,1390.36L1496.79,1371.3L1488.23,1350.72L1447.35,1359.28L1425.81,1390.99L1380.98,1388.54L1349.9,1359.19L1335.28,1355.68Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1342.99,1229.73L1436.19,1269.4L1444.38,1292.32L1454.54,1301.03L1466.02,1304.1L1550.4,1285.97L1531.34,1179.38L1339.29,1172.42L1342.88,1187.26L1342.99,1229.73Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1122.01,1479.81L1121.9,1330.86L1252.35,1415.63L1266.76,1447.61L1265.04,1505.09L1251.42,1520.96L1251.36,1488.14L1122.01,1479.81Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1225.95,1560.69L1271.7,1507.48L1290.03,1507.09L1306.7,1520.5L1304.18,1561.33L1225.95,1560.69Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1294.02,1502.73L1307.47,1513.97L1327.02,1475.84L1330.44,1368.52L1305.54,1415.56L1300.37,1431.64L1300.15,1456.49L1308.52,1464.98L1308.66,1471.25L1301.3,1465.13L1300.59,1472.2L1308.32,1479.51L1303.34,1490.92L1294.02,1502.73Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1319.31,1653.43L1300.9,1622.61L1303.27,1590.71L1312.17,1563.47L1313.65,1516.89L1333.87,1476.6L1337.33,1369.31L1358.39,1394.97L1367.61,1416.27L1389.11,1415.39L1383.71,1404.12L1384.66,1396.91L1421.37,1399.09L1428.97,1416.23L1431.1,1447.85L1435.6,1501.01L1431.99,1541.31L1356.37,1595.14L1353,1653.45L1319.31,1653.43Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1437.4,1434.44L1443.24,1500L1439.51,1538.98L1492.31,1540.31L1492.65,1527.73L1513.79,1491.35L1515.5,1429.95L1507,1421.13L1489.44,1420.3L1482.66,1415.11L1467.31,1413.78L1459.74,1422.92L1437.4,1434.44Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1437.67,1637.31L1437.86,1546.36L1498.42,1547.25L1504.31,1554.45L1501.84,1562.04L1499.99,1583.2L1498.26,1601.65L1493.04,1609.13L1490.97,1639.45L1437.67,1637.31Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1315.34,1719.63L1317.53,1661.65L1295.88,1626.34L1296.47,1591.27L1303.87,1568.06L1182.16,1565.34L1195.23,1582.38L1191.75,1588.13L1199.06,1592.68L1206.12,1592.82L1211.74,1596.92L1208.88,1601.57L1215.09,1604.93L1222.28,1604.77L1230.77,1609.52L1255.61,1636.44L1261.08,1651.31L1262.54,1665.17L1273.37,1665.61L1273.68,1672.2L1263.75,1672.03L1263.05,1678.53L1273.12,1680.15L1272.68,1685.66L1261.76,1684.92L1252.39,1715.27L1315.34,1719.63Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1435.02,1726.74L1437.5,1644.47L1490.46,1645.94L1485,1651.16L1486.01,1666.15L1479.87,1677.06L1473.8,1697.54L1477.12,1705.41L1472.65,1715.87L1478.64,1727.79L1435.02,1726.74Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1320.62,1719.93L1323.74,1659.79L1359.29,1660.75L1362.52,1599.88L1431.79,1549.26L1428.19,1726.65L1320.62,1719.93Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1556.82,1288.27L1580.7,1280.65L1617.62,1239.25L1641.37,1197.25L1641.56,1185.01L1633.1,1167.31L1611.94,1155.72L1591.18,1151.08L1581.47,1151.63L1571.76,1152.37L1562.92,1141.28L1556.82,1142.84L1539.27,1179.39L1556.82,1288.27Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1262.54,1256.14L1244.11,1227.86L1250.3,1209.58L1252.05,1199.15L1250.04,1182.01L1249.18,1146.76L1307.42,1147.14L1332.25,1173.41L1336.32,1191.34L1335.51,1231.01L1327.78,1241.95L1284.74,1240.84L1262.54,1256.14Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1417.74,1167.99L1417.15,1079.32L1556.42,1079.32L1553.41,1135.55L1535.47,1171.38L1417.74,1167.99Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                        </g>
                        <path id="North" d="M1008.53,1133.5L1159.13,1133.91L1161.07,1176.21L1242.61,1177.83L1242.84,1140.38L1309.25,1140.9L1334.41,1165.96L1410.91,1168.29L1410.03,1077.04L1049.36,1076.38L1026.99,1089.92L1009.86,1105.96L1000.18,1118.41L1008.53,1133.5Z" fill="rgb(132,190,145)"/>
                        <clipPath id="_clip2">
                            <path id="North1" d="M1008.53,1133.5L1159.13,1133.91L1161.07,1176.21L1242.61,1177.83L1242.84,1140.38L1309.25,1140.9L1334.41,1165.96L1410.91,1168.29L1410.03,1077.04L1049.36,1076.38L1026.99,1089.92L1009.86,1105.96L1000.18,1118.41L1008.53,1133.5Z"/>
                        </clipPath>
                        <g clip-path="url(#_clip2)">
                            <path d="M1191.02,1750.53L1236.35,1731.8L1237.79,1806.68L1188.44,1807.16L1191.02,1750.53Z" fill="rgb(132,190,145)"/>
                        </g>
                        <path id="North2" d="M1008.53,1133.5L1159.13,1133.91L1161.07,1176.21L1242.61,1177.83L1242.84,1140.38L1309.25,1140.9L1334.41,1165.96L1410.91,1168.29L1410.03,1077.04L1049.36,1076.38L1026.99,1089.92L1009.86,1105.96L1000.18,1118.41L1008.53,1133.5Z" fill="none" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                        <g id="South">
                            <path d="M1251.84,1721.08L1478.84,1736.29L1475.04,1771.15L1504.99,1807.1L1246.04,1807.37L1251.84,1721.08Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M941.07,1808.52L997.536,1783.5L1021.64,1763.41L1073.97,1699.22L1095.81,1706.67L1100.35,1727.45L1134.22,1769.97L1154.38,1758.69L1173.01,1762.71L1175.94,1807.39L941.07,1808.52Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                            <path d="M1191.29,1807.87L1192.98,1752.14L1221.88,1749.63L1223.26,1737.48L1237.24,1730.81L1238.8,1807.65L1191.29,1807.87Z" fill="rgb(132,190,145)" stroke="rgb(132,190,145)" stroke-width="4.17px"/>
                        </g>
                    </g>
                    <g id="locations">
                        <g transform="matrix(0.0876408,0,0,0.0876408,779.007,1505.69)">
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5119.61,728.007C5093.17,687.16 5091.19,635.128 5114.44,592.387C5137.69,549.646 5182.45,523.04 5231.1,523.04L5241.48,523.04C5290.37,523.04 5335.3,549.901 5358.45,592.962C5381.6,636.024 5379.22,688.322 5352.26,729.102C5307.35,797.007 5264.05,862.488 5245.07,891.19C5242.86,894.539 5239.1,896.545 5235.09,896.525C5231.07,896.506 5227.34,894.462 5225.15,891.091C5206.45,862.184 5163.76,796.225 5119.61,728.007Z" fill="rgb(50,128,171)"/>
                            </g>
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5114.93,731.033C5159.08,799.251 5201.77,865.21 5220.48,894.118C5223.68,899.066 5229.16,902.066 5235.06,902.095C5240.95,902.124 5246.47,899.179 5249.72,894.262C5268.7,865.56 5312,800.079 5356.9,732.174C5385,689.684 5387.48,635.192 5363.36,590.325C5339.24,545.458 5292.42,517.47 5241.48,517.47L5231.1,517.47C5180.4,517.47 5133.77,545.192 5109.54,589.725C5085.32,634.259 5087.39,688.474 5114.93,731.033ZM5124.28,724.981C5098.95,685.847 5097.06,635.997 5119.33,595.048C5141.6,554.1 5184.49,528.61 5231.1,528.61L5241.48,528.61C5288.32,528.61 5331.37,554.344 5353.55,595.6C5375.73,636.855 5373.44,686.96 5347.61,726.03C5302.7,793.935 5259.4,859.415 5240.43,888.117C5239.25,889.899 5237.25,890.966 5235.11,890.956C5232.98,890.945 5230.99,889.858 5229.83,888.065C5211.12,859.158 5168.43,793.199 5124.28,724.981Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,4420.24,-2.63117)">
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1.69841,0,0,1.69841,272.505,-9.97708)">
                                    <circle cx="250.546" cy="266.544" r="19.541" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,547.116,5.27787)">
                                    <circle cx="297.693" cy="417.911" r="20.782" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)"/>
                                </g>
                                <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838" fill="rgb(255,166,133)"/>
                                <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z" fill="rgb(51,51,51)"/>
                            </g>
                        </g>
                        <g transform="matrix(0.0876408,0,0,0.0876408,796.007,1298.07)">
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5119.61,728.007C5093.17,687.16 5091.19,635.128 5114.44,592.387C5137.69,549.646 5182.45,523.04 5231.1,523.04L5241.48,523.04C5290.37,523.04 5335.3,549.901 5358.45,592.962C5381.6,636.024 5379.22,688.322 5352.26,729.102C5307.35,797.007 5264.05,862.488 5245.07,891.19C5242.86,894.539 5239.1,896.545 5235.09,896.525C5231.07,896.506 5227.34,894.462 5225.15,891.091C5206.45,862.184 5163.76,796.225 5119.61,728.007Z" fill="rgb(50,128,171)"/>
                            </g>
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5114.93,731.033C5159.08,799.251 5201.77,865.21 5220.48,894.118C5223.68,899.066 5229.16,902.066 5235.06,902.095C5240.95,902.124 5246.47,899.179 5249.72,894.262C5268.7,865.56 5312,800.079 5356.9,732.174C5385,689.684 5387.48,635.192 5363.36,590.325C5339.24,545.458 5292.42,517.47 5241.48,517.47L5231.1,517.47C5180.4,517.47 5133.77,545.192 5109.54,589.725C5085.32,634.259 5087.39,688.474 5114.93,731.033ZM5124.28,724.981C5098.95,685.847 5097.06,635.997 5119.33,595.048C5141.6,554.1 5184.49,528.61 5231.1,528.61L5241.48,528.61C5288.32,528.61 5331.37,554.344 5353.55,595.6C5375.73,636.855 5373.44,686.96 5347.61,726.03C5302.7,793.935 5259.4,859.415 5240.43,888.117C5239.25,889.899 5237.25,890.966 5235.11,890.956C5232.98,890.945 5230.99,889.858 5229.83,888.065C5211.12,859.158 5168.43,793.199 5124.28,724.981Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,4420.24,-2.63117)">
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1.69841,0,0,1.69841,272.505,-9.97708)">
                                    <circle cx="250.546" cy="266.544" r="19.541" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,547.116,5.27787)">
                                    <circle cx="297.693" cy="417.911" r="20.782" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)"/>
                                </g>
                                <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838" fill="rgb(255,166,133)"/>
                                <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z" fill="rgb(51,51,51)"/>
                            </g>
                        </g>
                        <g transform="matrix(0.0876408,0,0,0.0876408,875.347,1226.26)">
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5119.61,728.007C5093.17,687.16 5091.19,635.128 5114.44,592.387C5137.69,549.646 5182.45,523.04 5231.1,523.04L5241.48,523.04C5290.37,523.04 5335.3,549.901 5358.45,592.962C5381.6,636.024 5379.22,688.322 5352.26,729.102C5307.35,797.007 5264.05,862.488 5245.07,891.19C5242.86,894.539 5239.1,896.545 5235.09,896.525C5231.07,896.506 5227.34,894.462 5225.15,891.091C5206.45,862.184 5163.76,796.225 5119.61,728.007Z" fill="rgb(50,128,171)"/>
                            </g>
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5114.93,731.033C5159.08,799.251 5201.77,865.21 5220.48,894.118C5223.68,899.066 5229.16,902.066 5235.06,902.095C5240.95,902.124 5246.47,899.179 5249.72,894.262C5268.7,865.56 5312,800.079 5356.9,732.174C5385,689.684 5387.48,635.192 5363.36,590.325C5339.24,545.458 5292.42,517.47 5241.48,517.47L5231.1,517.47C5180.4,517.47 5133.77,545.192 5109.54,589.725C5085.32,634.259 5087.39,688.474 5114.93,731.033ZM5124.28,724.981C5098.95,685.847 5097.06,635.997 5119.33,595.048C5141.6,554.1 5184.49,528.61 5231.1,528.61L5241.48,528.61C5288.32,528.61 5331.37,554.344 5353.55,595.6C5375.73,636.855 5373.44,686.96 5347.61,726.03C5302.7,793.935 5259.4,859.415 5240.43,888.117C5239.25,889.899 5237.25,890.966 5235.11,890.956C5232.98,890.945 5230.99,889.858 5229.83,888.065C5211.12,859.158 5168.43,793.199 5124.28,724.981Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,4420.24,-2.63117)">
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1.69841,0,0,1.69841,272.505,-9.97708)">
                                    <circle cx="250.546" cy="266.544" r="19.541" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,547.116,5.27787)">
                                    <circle cx="297.693" cy="417.911" r="20.782" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)"/>
                                </g>
                                <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838" fill="rgb(255,166,133)"/>
                                <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z" fill="rgb(51,51,51)"/>
                            </g>
                        </g>
                        <g transform="matrix(0.0876408,0,0,0.0876408,644.314,1196.46)">
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5119.61,728.007C5093.17,687.16 5091.19,635.128 5114.44,592.387C5137.69,549.646 5182.45,523.04 5231.1,523.04L5241.48,523.04C5290.37,523.04 5335.3,549.901 5358.45,592.962C5381.6,636.024 5379.22,688.322 5352.26,729.102C5307.35,797.007 5264.05,862.488 5245.07,891.19C5242.86,894.539 5239.1,896.545 5235.09,896.525C5231.07,896.506 5227.34,894.462 5225.15,891.091C5206.45,862.184 5163.76,796.225 5119.61,728.007Z" fill="rgb(50,128,171)"/>
                            </g>
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5114.93,731.033C5159.08,799.251 5201.77,865.21 5220.48,894.118C5223.68,899.066 5229.16,902.066 5235.06,902.095C5240.95,902.124 5246.47,899.179 5249.72,894.262C5268.7,865.56 5312,800.079 5356.9,732.174C5385,689.684 5387.48,635.192 5363.36,590.325C5339.24,545.458 5292.42,517.47 5241.48,517.47L5231.1,517.47C5180.4,517.47 5133.77,545.192 5109.54,589.725C5085.32,634.259 5087.39,688.474 5114.93,731.033ZM5124.28,724.981C5098.95,685.847 5097.06,635.997 5119.33,595.048C5141.6,554.1 5184.49,528.61 5231.1,528.61L5241.48,528.61C5288.32,528.61 5331.37,554.344 5353.55,595.6C5375.73,636.855 5373.44,686.96 5347.61,726.03C5302.7,793.935 5259.4,859.415 5240.43,888.117C5239.25,889.899 5237.25,890.966 5235.11,890.956C5232.98,890.945 5230.99,889.858 5229.83,888.065C5211.12,859.158 5168.43,793.199 5124.28,724.981Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,4420.24,-2.63117)">
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1.69841,0,0,1.69841,272.505,-9.97708)">
                                    <circle cx="250.546" cy="266.544" r="19.541" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,547.116,5.27787)">
                                    <circle cx="297.693" cy="417.911" r="20.782" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)"/>
                                </g>
                                <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838" fill="rgb(255,166,133)"/>
                                <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z" fill="rgb(51,51,51)"/>
                            </g>
                        </g>
                        <g transform="matrix(0.0876408,0,0,0.0876408,683.244,1219.44)">
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5119.61,728.007C5093.17,687.16 5091.19,635.128 5114.44,592.387C5137.69,549.646 5182.45,523.04 5231.1,523.04L5241.48,523.04C5290.37,523.04 5335.3,549.901 5358.45,592.962C5381.6,636.024 5379.22,688.322 5352.26,729.102C5307.35,797.007 5264.05,862.488 5245.07,891.19C5242.86,894.539 5239.1,896.545 5235.09,896.525C5231.07,896.506 5227.34,894.462 5225.15,891.091C5206.45,862.184 5163.76,796.225 5119.61,728.007Z" fill="rgb(50,128,171)"/>
                            </g>
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5114.93,731.033C5159.08,799.251 5201.77,865.21 5220.48,894.118C5223.68,899.066 5229.16,902.066 5235.06,902.095C5240.95,902.124 5246.47,899.179 5249.72,894.262C5268.7,865.56 5312,800.079 5356.9,732.174C5385,689.684 5387.48,635.192 5363.36,590.325C5339.24,545.458 5292.42,517.47 5241.48,517.47L5231.1,517.47C5180.4,517.47 5133.77,545.192 5109.54,589.725C5085.32,634.259 5087.39,688.474 5114.93,731.033ZM5124.28,724.981C5098.95,685.847 5097.06,635.997 5119.33,595.048C5141.6,554.1 5184.49,528.61 5231.1,528.61L5241.48,528.61C5288.32,528.61 5331.37,554.344 5353.55,595.6C5375.73,636.855 5373.44,686.96 5347.61,726.03C5302.7,793.935 5259.4,859.415 5240.43,888.117C5239.25,889.899 5237.25,890.966 5235.11,890.956C5232.98,890.945 5230.99,889.858 5229.83,888.065C5211.12,859.158 5168.43,793.199 5124.28,724.981Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,4420.24,-2.63117)">
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1.69841,0,0,1.69841,272.505,-9.97708)">
                                    <circle cx="250.546" cy="266.544" r="19.541" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,547.116,5.27787)">
                                    <circle cx="297.693" cy="417.911" r="20.782" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)"/>
                                </g>
                                <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838" fill="rgb(255,166,133)"/>
                                <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z" fill="rgb(51,51,51)"/>
                            </g>
                        </g>
                        <g transform="matrix(0.0876408,0,0,0.0876408,955.75,1303.04)">
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5119.61,728.007C5093.17,687.16 5091.19,635.128 5114.44,592.387C5137.69,549.646 5182.45,523.04 5231.1,523.04L5241.48,523.04C5290.37,523.04 5335.3,549.901 5358.45,592.962C5381.6,636.024 5379.22,688.322 5352.26,729.102C5307.35,797.007 5264.05,862.488 5245.07,891.19C5242.86,894.539 5239.1,896.545 5235.09,896.525C5231.07,896.506 5227.34,894.462 5225.15,891.091C5206.45,862.184 5163.76,796.225 5119.61,728.007Z" fill="rgb(50,128,171)"/>
                            </g>
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5114.93,731.033C5159.08,799.251 5201.77,865.21 5220.48,894.118C5223.68,899.066 5229.16,902.066 5235.06,902.095C5240.95,902.124 5246.47,899.179 5249.72,894.262C5268.7,865.56 5312,800.079 5356.9,732.174C5385,689.684 5387.48,635.192 5363.36,590.325C5339.24,545.458 5292.42,517.47 5241.48,517.47L5231.1,517.47C5180.4,517.47 5133.77,545.192 5109.54,589.725C5085.32,634.259 5087.39,688.474 5114.93,731.033ZM5124.28,724.981C5098.95,685.847 5097.06,635.997 5119.33,595.048C5141.6,554.1 5184.49,528.61 5231.1,528.61L5241.48,528.61C5288.32,528.61 5331.37,554.344 5353.55,595.6C5375.73,636.855 5373.44,686.96 5347.61,726.03C5302.7,793.935 5259.4,859.415 5240.43,888.117C5239.25,889.899 5237.25,890.966 5235.11,890.956C5232.98,890.945 5230.99,889.858 5229.83,888.065C5211.12,859.158 5168.43,793.199 5124.28,724.981Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,4420.24,-2.63117)">
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1.69841,0,0,1.69841,272.505,-9.97708)">
                                    <circle cx="250.546" cy="266.544" r="19.541" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,547.116,5.27787)">
                                    <circle cx="297.693" cy="417.911" r="20.782" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)"/>
                                </g>
                                <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838" fill="rgb(255,166,133)"/>
                                <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z" fill="rgb(51,51,51)"/>
                            </g>
                        </g>
                        <g transform="matrix(0.0876408,0,0,0.0876408,1003.97,1071.07)">
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5119.61,728.007C5093.17,687.16 5091.19,635.128 5114.44,592.387C5137.69,549.646 5182.45,523.04 5231.1,523.04L5241.48,523.04C5290.37,523.04 5335.3,549.901 5358.45,592.962C5381.6,636.024 5379.22,688.322 5352.26,729.102C5307.35,797.007 5264.05,862.488 5245.07,891.19C5242.86,894.539 5239.1,896.545 5235.09,896.525C5231.07,896.506 5227.34,894.462 5225.15,891.091C5206.45,862.184 5163.76,796.225 5119.61,728.007Z" fill="rgb(50,128,171)"/>
                            </g>
                            <g transform="matrix(1.87016,0,0,1.87016,-4683.94,-733.251)">
                                <path d="M5114.93,731.033C5159.08,799.251 5201.77,865.21 5220.48,894.118C5223.68,899.066 5229.16,902.066 5235.06,902.095C5240.95,902.124 5246.47,899.179 5249.72,894.262C5268.7,865.56 5312,800.079 5356.9,732.174C5385,689.684 5387.48,635.192 5363.36,590.325C5339.24,545.458 5292.42,517.47 5241.48,517.47L5231.1,517.47C5180.4,517.47 5133.77,545.192 5109.54,589.725C5085.32,634.259 5087.39,688.474 5114.93,731.033ZM5124.28,724.981C5098.95,685.847 5097.06,635.997 5119.33,595.048C5141.6,554.1 5184.49,528.61 5231.1,528.61L5241.48,528.61C5288.32,528.61 5331.37,554.344 5353.55,595.6C5375.73,636.855 5373.44,686.96 5347.61,726.03C5302.7,793.935 5259.4,859.415 5240.43,888.117C5239.25,889.899 5237.25,890.966 5235.11,890.956C5232.98,890.945 5230.99,889.858 5229.83,888.065C5211.12,859.158 5168.43,793.199 5124.28,724.981Z" fill="rgb(51,51,51)"/>
                            </g>
                            <g transform="matrix(1,0,0,1,4420.24,-2.63117)">
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M976.167,2236.01C957.254,2220.2 913.081,2213.6 896.129,2236.61C890.263,2244.57 896.368,2254.43 891.759,2262.08C887.118,2269.77 878.119,2272.96 873.667,2280.9C868.08,2290.88 874.465,2292.78 873.012,2300.81C871.706,2308.02 866.227,2314.04 864.857,2321.22C861.72,2337.66 872.473,2348.75 881.23,2361.27" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M982.851,2228.03C969.769,2217.08 946.272,2209.74 925.294,2211.52C910.047,2212.82 896.264,2218.86 887.743,2230.43C883.933,2235.6 882.915,2241.18 882.968,2246.91C882.988,2249.06 883.174,2251.22 883.215,2253.35C883.237,2254.51 883.451,2255.68 882.838,2256.7C880.655,2260.32 877.041,2262.45 873.906,2265.1C870.293,2268.14 866.992,2271.51 864.579,2275.81C860.739,2282.67 860.564,2287.39 861.128,2291.37C861.353,2292.96 861.747,2294.43 862.196,2295.91C862.463,2296.79 862.999,2297.64 862.762,2298.95C862.154,2302.31 860.187,2305.25 858.636,2308.33C856.866,2311.86 855.353,2315.46 854.625,2319.27C852.56,2330.09 854.917,2339.18 859.381,2347.6C862.965,2354.35 868.068,2360.63 872.695,2367.24C875.991,2371.95 882.491,2373.1 887.201,2369.8C891.912,2366.51 893.061,2360.01 889.765,2355.3C886.364,2350.43 882.64,2345.8 879.606,2340.97C876.292,2335.68 873.759,2330.15 875.089,2323.17C875.734,2319.8 877.726,2316.83 879.288,2313.7C881.063,2310.16 882.564,2306.52 883.262,2302.66C884.026,2298.44 883.59,2295.19 882.819,2292.23C882.361,2290.47 881.011,2289.11 882.755,2285.99C884.782,2282.38 888.275,2280.29 891.326,2277.75C894.898,2274.78 898.206,2271.56 900.679,2267.45C903.605,2262.6 904.219,2257.27 904.008,2251.71C903.936,2249.81 903.764,2247.88 903.804,2245.97C903.827,2244.89 903.793,2243.77 904.516,2242.78C909.558,2235.94 918.042,2233.05 927.063,2232.28C942.483,2230.97 959.869,2235.96 969.484,2244.01C973.894,2247.69 980.469,2247.11 984.158,2242.7C987.846,2238.29 987.261,2231.71 982.851,2228.03Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1128.9,2474.87C1138.5,2483.59 1157.14,2499.65 1171.23,2497.44C1177.16,2496.51 1180.97,2488.22 1182.32,2483.24C1185.87,2470.14 1181.15,2453.16 1174.16,2441.78" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M1168.82,2487.23C1169.43,2486.24 1171.78,2482.32 1172.27,2480.52C1175.12,2469.98 1170.91,2456.39 1165.29,2447.24C1162.28,2442.34 1163.81,2435.92 1168.7,2432.91C1173.6,2429.9 1180.02,2431.43 1183.03,2436.33C1191.39,2449.93 1196.62,2470.31 1192.38,2485.97C1191.26,2490.1 1188.84,2495.84 1185.37,2500.07C1181.99,2504.21 1177.64,2506.98 1172.84,2507.73C1165.22,2508.93 1156.51,2506.64 1148.1,2502.15C1137.97,2496.74 1128.15,2488.26 1121.9,2482.58C1117.64,2478.72 1117.32,2472.13 1121.18,2467.87C1125.05,2463.61 1131.64,2463.29 1135.9,2467.16C1141.17,2471.95 1149.37,2479.21 1157.91,2483.77C1161.66,2485.77 1165.38,2487.46 1168.82,2487.23Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M887.076,2416.21C858.477,2238 1081.72,2148.86 1184.74,2281.61C1257.59,2375.49 1155.76,2475.66 1065.78,2498.16C1006.4,2513.01 932.227,2506.69 877.939,2478.33" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M897.361,2414.56C870.529,2247.36 1079.85,2163.45 1176.51,2288C1209.61,2330.64 1202.85,2374.72 1177.78,2410.7C1151.56,2448.3 1105.98,2477.37 1063.25,2488.06C1006.2,2502.32 934.924,2496.35 882.763,2469.09C877.668,2466.43 871.369,2468.41 868.707,2473.5C866.044,2478.6 868.02,2484.89 873.115,2487.56C929.531,2517.03 1006.6,2523.7 1068.31,2508.27C1115.56,2496.45 1165.88,2464.19 1194.87,2422.61C1224.99,2379.4 1232.73,2326.45 1192.97,2275.22C1083.58,2134.28 846.425,2228.64 876.791,2417.87C877.702,2423.54 883.05,2427.41 888.726,2426.5C894.403,2425.59 898.272,2420.24 897.361,2414.56Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M971.006,2473.8C963.443,2481.02 939.784,2505.84 943.988,2517.65C948.486,2530.29 967.95,2530.45 978.551,2528.51C1006.2,2523.45 1025.4,2503.81 1030.68,2476.39" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-317.618,-1882.95)">
                                    <path d="M953.93,2514.48C955.064,2517.07 958.126,2517.74 961.061,2518.34C966.45,2519.44 972.448,2519.03 976.678,2518.26C999.918,2514.01 1016.01,2497.47 1020.45,2474.42C1021.53,2468.78 1027,2465.07 1032.64,2466.16C1038.29,2467.25 1041.99,2472.71 1040.91,2478.36C1034.79,2510.16 1012.49,2532.89 980.425,2538.75C972.646,2540.18 960.972,2540.52 951.684,2537.35C943.522,2534.57 937.075,2529.3 934.174,2521.15C932.152,2515.47 933.135,2507.74 937.252,2499.62C943.779,2486.76 958.041,2471.78 963.81,2466.27C967.967,2462.29 974.566,2462.45 978.538,2466.6C982.509,2470.76 982.359,2477.36 978.202,2481.33C974.005,2485.34 964.42,2495.19 958.285,2504.8C956.878,2507 955.66,2509.18 954.815,2511.26C954.385,2512.31 954.02,2514.04 953.93,2514.48Z" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1.69841,0,0,1.69841,272.505,-9.97708)">
                                    <circle cx="250.546" cy="266.544" r="19.541" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,547.116,5.27787)">
                                    <circle cx="297.693" cy="417.911" r="20.782" fill="rgb(51,51,51)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M728.93,478.779C749.024,506.912 785.683,502.008 795.418,467.93" fill="rgb(255,166,133)"/>
                                </g>
                                <g transform="matrix(1,0,0,1,21,0)">
                                    <path d="M720.454,484.833C733.157,502.619 751.329,509.471 767.631,506.981C784.051,504.473 799.235,492.49 805.434,470.791C807.013,465.263 803.807,459.493 798.279,457.914C792.751,456.335 786.981,459.541 785.402,465.069C781.865,477.452 773.856,484.955 764.486,486.386C754.999,487.835 744.799,483.075 737.407,472.725C734.066,468.047 727.554,466.961 722.876,470.303C718.198,473.644 717.112,480.155 720.454,484.833Z" fill="rgb(51,51,51)"/>
                                </g>
                                <path d="M569.198,533.37C548.242,519.198 527.795,502.912 508.537,486.394C501.963,480.756 495.177,475.488 488.896,469.507C488.07,468.72 478.805,460.188 478.72,460.298C472.674,468.135 476.997,485.279 479.723,493.707C484.613,508.823 490.786,524.542 500.072,537.51C507.091,547.311 517.031,557.091 522.389,567.862C532.487,588.161 503.288,605.377 505.091,621.66C505.594,626.208 519.425,625.643 522.025,625.191C539.13,622.215 546.329,602.52 561.169,595.838" fill="rgb(255,166,133)"/>
                                <path d="M517.225,614.912C518.616,614.976 519.827,615 520.24,614.929C528.207,613.542 532.925,606.831 538.297,601.086C543.872,595.124 549.72,589.57 556.892,586.34C562.134,583.98 568.307,586.319 570.667,591.561C573.028,596.804 570.688,602.976 565.446,605.337C559.236,608.133 554.866,613.928 550.018,619.059C542.668,626.838 534.662,633.566 523.81,635.454C521.335,635.884 511.381,636.32 505.394,634.463C498.667,632.377 495.276,627.671 494.737,622.806C493.78,614.155 498.438,604.929 504.681,595.699C507.239,591.917 509.982,588.063 511.96,584.066C513.782,580.385 515.088,576.573 513.062,572.502C507.94,562.204 498.313,552.945 491.603,543.575C481.702,529.748 475.026,513.031 469.812,496.913C467.946,491.145 465.492,481.784 465.263,473.298C465.058,465.694 466.765,458.742 470.472,453.936C470.841,453.458 478.116,446.208 485.673,452.507C487.324,453.883 495.298,461.219 496.08,461.964C502.229,467.82 508.882,472.967 515.319,478.488C534.278,494.749 554.403,510.789 575.033,524.742C579.796,527.962 581.047,534.444 577.827,539.206C574.606,543.968 568.125,545.22 563.362,541.999C542.082,527.607 521.312,511.075 501.756,494.301C496.972,490.199 492.083,486.283 487.351,482.158C488.047,485.346 488.914,488.275 489.634,490.5C494.2,504.615 499.871,519.337 508.542,531.445C515.869,541.678 526.122,551.978 531.715,563.222C538.56,576.983 533.445,589.938 525.566,601.973C522.808,606.184 519.783,610.262 517.576,614.267L517.225,614.912Z" fill="rgb(51,51,51)"/>
                            </g>
                        </g>
                    </g>
                    <g id="names">
                        <g transform="matrix(6.12323e-17,-1,1,6.12323e-17,89.7565,2404.23)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.325,0.008C0.291,0.008 0.268,-0.01 0.255,-0.046L0.046,-0.637C0.039,-0.658 0.04,-0.676 0.051,-0.691C0.061,-0.706 0.078,-0.713 0.103,-0.713C0.121,-0.713 0.135,-0.709 0.145,-0.701C0.155,-0.692 0.163,-0.679 0.169,-0.661L0.329,-0.185L0.497,-0.66C0.51,-0.695 0.531,-0.713 0.561,-0.713C0.577,-0.713 0.59,-0.709 0.599,-0.7C0.608,-0.691 0.616,-0.678 0.623,-0.659L0.788,-0.178L0.953,-0.661C0.959,-0.678 0.967,-0.691 0.977,-0.7C0.987,-0.709 1.001,-0.713 1.018,-0.713C1.039,-0.713 1.055,-0.706 1.065,-0.691C1.075,-0.676 1.076,-0.658 1.069,-0.637L0.858,-0.045C0.851,-0.027 0.843,-0.014 0.832,-0.005C0.821,0.004 0.807,0.008 0.789,0.008C0.771,0.008 0.757,0.004 0.746,-0.005C0.735,-0.014 0.727,-0.027 0.72,-0.046L0.557,-0.509L0.393,-0.045C0.386,-0.027 0.378,-0.014 0.368,-0.005C0.357,0.004 0.343,0.008 0.325,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1046.49,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1057.89,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1064.53,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1071.18,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1076.49,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1088.68,1208.23)">
                                <path d="M0.293,0.19C0.257,0.19 0.222,0.187 0.189,0.18C0.155,0.173 0.124,0.163 0.097,0.149C0.081,0.141 0.071,0.131 0.069,0.119C0.066,0.106 0.067,0.094 0.072,0.083C0.077,0.072 0.086,0.064 0.097,0.059C0.108,0.054 0.119,0.054 0.132,0.06C0.161,0.075 0.189,0.084 0.216,0.089C0.242,0.093 0.264,0.095 0.283,0.095C0.372,0.095 0.417,0.052 0.417,-0.034L0.417,-0.101C0.403,-0.074 0.382,-0.052 0.353,-0.036C0.323,-0.02 0.291,-0.012 0.256,-0.012C0.212,-0.012 0.174,-0.022 0.141,-0.043C0.108,-0.063 0.083,-0.091 0.065,-0.128C0.047,-0.165 0.038,-0.207 0.038,-0.256C0.038,-0.305 0.047,-0.347 0.065,-0.384C0.083,-0.42 0.108,-0.448 0.141,-0.469C0.174,-0.489 0.212,-0.499 0.256,-0.499C0.291,-0.499 0.323,-0.491 0.352,-0.476C0.381,-0.461 0.402,-0.44 0.415,-0.413L0.415,-0.434C0.415,-0.455 0.421,-0.47 0.432,-0.481C0.443,-0.492 0.458,-0.497 0.477,-0.497C0.518,-0.497 0.539,-0.476 0.539,-0.434L0.539,-0.049C0.539,0.03 0.518,0.09 0.476,0.13C0.434,0.17 0.373,0.19 0.293,0.19ZM0.29,-0.107C0.329,-0.107 0.359,-0.12 0.382,-0.147C0.405,-0.174 0.416,-0.21 0.416,-0.256C0.416,-0.302 0.405,-0.338 0.382,-0.365C0.359,-0.391 0.329,-0.404 0.29,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.302 0.164,-0.256C0.164,-0.21 0.175,-0.174 0.198,-0.147C0.221,-0.12 0.251,-0.107 0.29,-0.107Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1101.26,1208.23)">
                                <path d="M0.161,0.008C0.119,0.008 0.098,-0.013 0.098,-0.056L0.098,-0.395L0.052,-0.395C0.019,-0.395 0.002,-0.411 0.002,-0.442C0.002,-0.473 0.019,-0.489 0.052,-0.489L0.098,-0.489L0.098,-0.49C0.098,-0.559 0.115,-0.61 0.15,-0.645C0.185,-0.679 0.235,-0.699 0.3,-0.705L0.334,-0.708C0.351,-0.709 0.364,-0.706 0.372,-0.697C0.38,-0.688 0.384,-0.678 0.385,-0.666C0.385,-0.653 0.382,-0.642 0.375,-0.633C0.368,-0.623 0.359,-0.617 0.346,-0.616L0.332,-0.615C0.294,-0.612 0.266,-0.603 0.249,-0.586C0.232,-0.569 0.223,-0.544 0.223,-0.51L0.223,-0.489L0.308,-0.489C0.341,-0.489 0.358,-0.473 0.358,-0.442C0.358,-0.411 0.341,-0.395 0.308,-0.395L0.223,-0.395L0.223,-0.056C0.223,-0.013 0.202,0.008 0.161,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1108.57,1208.23)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1120.57,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1128.57,1208.23)">
                                <path d="M0.253,0.01C0.21,0.01 0.173,-0 0.141,-0.021C0.108,-0.042 0.083,-0.071 0.065,-0.11C0.047,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.298 0.047,-0.343 0.065,-0.381C0.083,-0.418 0.108,-0.447 0.141,-0.468C0.173,-0.489 0.21,-0.499 0.253,-0.499C0.288,-0.499 0.319,-0.491 0.347,-0.476C0.375,-0.461 0.396,-0.44 0.41,-0.415L0.41,-0.65C0.41,-0.692 0.431,-0.713 0.472,-0.713C0.514,-0.713 0.535,-0.692 0.535,-0.65L0.535,-0.056C0.535,-0.013 0.514,0.008 0.473,0.008C0.432,0.008 0.411,-0.013 0.411,-0.056L0.411,-0.077C0.397,-0.05 0.376,-0.029 0.348,-0.014C0.32,0.002 0.288,0.01 0.253,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(6.245e-17,-1,1,6.245e-17,260.284,2543.66)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.137,0.008C0.098,0.008 0.079,-0.012 0.079,-0.052L0.079,-0.653C0.079,-0.693 0.099,-0.713 0.139,-0.713C0.156,-0.713 0.169,-0.71 0.179,-0.704C0.188,-0.697 0.197,-0.686 0.205,-0.671L0.436,-0.244L0.667,-0.671C0.675,-0.686 0.684,-0.697 0.693,-0.704C0.702,-0.71 0.715,-0.713 0.732,-0.713C0.771,-0.713 0.79,-0.693 0.79,-0.653L0.79,-0.052C0.79,-0.012 0.771,0.008 0.732,0.008C0.693,0.008 0.674,-0.012 0.674,-0.052L0.674,-0.475L0.486,-0.132C0.479,-0.121 0.472,-0.112 0.465,-0.107C0.458,-0.102 0.448,-0.099 0.435,-0.099C0.422,-0.099 0.412,-0.102 0.404,-0.108C0.396,-0.113 0.389,-0.121 0.383,-0.132L0.195,-0.473L0.195,-0.052C0.195,-0.012 0.176,0.008 0.137,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1042.64,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1054.03,1208.23)">
                                <path d="M0.253,0.01C0.21,0.01 0.173,-0 0.141,-0.021C0.108,-0.042 0.083,-0.071 0.065,-0.11C0.047,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.298 0.047,-0.343 0.065,-0.381C0.083,-0.418 0.108,-0.447 0.141,-0.468C0.173,-0.489 0.21,-0.499 0.253,-0.499C0.288,-0.499 0.319,-0.491 0.347,-0.476C0.375,-0.461 0.396,-0.44 0.41,-0.415L0.41,-0.65C0.41,-0.692 0.431,-0.713 0.472,-0.713C0.514,-0.713 0.535,-0.692 0.535,-0.65L0.535,-0.056C0.535,-0.013 0.514,0.008 0.473,0.008C0.432,0.008 0.411,-0.013 0.411,-0.056L0.411,-0.077C0.397,-0.05 0.376,-0.029 0.348,-0.014C0.32,0.002 0.288,0.01 0.253,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1066.53,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1071.84,1208.23)">
                                <path d="M0.244,0.01C0.215,0.01 0.185,0.007 0.153,0.001C0.121,-0.005 0.092,-0.015 0.067,-0.03C0.053,-0.038 0.044,-0.048 0.041,-0.06C0.038,-0.071 0.038,-0.082 0.043,-0.093C0.047,-0.103 0.054,-0.11 0.065,-0.115C0.075,-0.12 0.087,-0.119 0.101,-0.112C0.128,-0.099 0.153,-0.091 0.176,-0.086C0.199,-0.081 0.222,-0.078 0.245,-0.078C0.277,-0.078 0.301,-0.084 0.317,-0.095C0.332,-0.106 0.34,-0.12 0.34,-0.138C0.34,-0.166 0.319,-0.184 0.278,-0.192L0.178,-0.211C0.093,-0.227 0.051,-0.271 0.051,-0.343C0.051,-0.375 0.06,-0.403 0.078,-0.426C0.095,-0.449 0.12,-0.467 0.151,-0.48C0.182,-0.493 0.218,-0.499 0.259,-0.499C0.318,-0.499 0.37,-0.486 0.416,-0.46C0.429,-0.453 0.436,-0.444 0.439,-0.433C0.441,-0.421 0.44,-0.41 0.435,-0.4C0.43,-0.389 0.422,-0.382 0.411,-0.378C0.4,-0.373 0.388,-0.374 0.374,-0.381C0.353,-0.392 0.334,-0.399 0.315,-0.404C0.296,-0.409 0.277,-0.411 0.26,-0.411C0.227,-0.411 0.203,-0.405 0.188,-0.394C0.172,-0.383 0.164,-0.368 0.164,-0.349C0.164,-0.32 0.183,-0.302 0.221,-0.295L0.321,-0.276C0.364,-0.268 0.397,-0.253 0.42,-0.232C0.442,-0.211 0.453,-0.182 0.453,-0.146C0.453,-0.097 0.434,-0.059 0.396,-0.032C0.358,-0.004 0.307,0.01 0.244,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1082.01,1208.23)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1094.01,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1229.07)">
                                <path d="M0.141,0.008C0.098,0.008 0.077,-0.014 0.077,-0.058L0.077,-0.64C0.077,-0.683 0.099,-0.705 0.142,-0.705L0.383,-0.705C0.46,-0.705 0.52,-0.686 0.562,-0.647C0.604,-0.608 0.625,-0.554 0.625,-0.485C0.625,-0.416 0.604,-0.362 0.562,-0.323C0.52,-0.284 0.46,-0.264 0.383,-0.264L0.205,-0.264L0.205,-0.058C0.205,-0.014 0.184,0.008 0.141,0.008ZM0.205,-0.365L0.363,-0.365C0.455,-0.365 0.501,-0.405 0.501,-0.485C0.501,-0.564 0.455,-0.604 0.363,-0.604L0.205,-0.604L0.205,-0.365Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1037.66,1229.07)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1049.05,1229.07)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1057.22,1229.07)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.281L0.192,-0.281L0.357,-0.455C0.37,-0.468 0.382,-0.479 0.392,-0.486C0.402,-0.493 0.416,-0.497 0.433,-0.497C0.45,-0.497 0.464,-0.493 0.473,-0.484C0.482,-0.474 0.486,-0.464 0.486,-0.451C0.486,-0.438 0.48,-0.426 0.468,-0.413L0.318,-0.255L0.485,-0.075C0.497,-0.062 0.503,-0.049 0.502,-0.037C0.501,-0.024 0.495,-0.013 0.485,-0.005C0.475,0.004 0.462,0.008 0.447,0.008C0.428,0.008 0.413,0.004 0.403,-0.003C0.392,-0.01 0.379,-0.021 0.366,-0.036L0.192,-0.218L0.19,-0.218L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(6.245e-17,-1,1,6.245e-17,264.873,2659.89)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.137,0.008C0.098,0.008 0.079,-0.012 0.079,-0.052L0.079,-0.653C0.079,-0.693 0.099,-0.713 0.139,-0.713C0.156,-0.713 0.169,-0.71 0.179,-0.704C0.188,-0.697 0.197,-0.686 0.205,-0.671L0.436,-0.244L0.667,-0.671C0.675,-0.686 0.684,-0.697 0.693,-0.704C0.702,-0.71 0.715,-0.713 0.732,-0.713C0.771,-0.713 0.79,-0.693 0.79,-0.653L0.79,-0.052C0.79,-0.012 0.771,0.008 0.732,0.008C0.693,0.008 0.674,-0.012 0.674,-0.052L0.674,-0.475L0.486,-0.132C0.479,-0.121 0.472,-0.112 0.465,-0.107C0.458,-0.102 0.448,-0.099 0.435,-0.099C0.422,-0.099 0.412,-0.102 0.404,-0.108C0.396,-0.113 0.389,-0.121 0.383,-0.132L0.195,-0.473L0.195,-0.052C0.195,-0.012 0.176,0.008 0.137,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1042.64,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1054.03,1208.23)">
                                <path d="M0.253,0.01C0.21,0.01 0.173,-0 0.141,-0.021C0.108,-0.042 0.083,-0.071 0.065,-0.11C0.047,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.298 0.047,-0.343 0.065,-0.381C0.083,-0.418 0.108,-0.447 0.141,-0.468C0.173,-0.489 0.21,-0.499 0.253,-0.499C0.288,-0.499 0.319,-0.491 0.347,-0.476C0.375,-0.461 0.396,-0.44 0.41,-0.415L0.41,-0.65C0.41,-0.692 0.431,-0.713 0.472,-0.713C0.514,-0.713 0.535,-0.692 0.535,-0.65L0.535,-0.056C0.535,-0.013 0.514,0.008 0.473,0.008C0.432,0.008 0.411,-0.013 0.411,-0.056L0.411,-0.077C0.397,-0.05 0.376,-0.029 0.348,-0.014C0.32,0.002 0.288,0.01 0.253,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1066.53,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1074.53,1208.23)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1086.53,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1098.72,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(6.245e-17,-1,1,6.245e-17,255.287,2740)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.142,-0C0.099,-0 0.077,-0.022 0.077,-0.066L0.077,-0.643C0.077,-0.686 0.098,-0.708 0.141,-0.708C0.184,-0.708 0.205,-0.686 0.205,-0.643L0.205,-0.109L0.495,-0.109C0.532,-0.109 0.551,-0.091 0.551,-0.055C0.551,-0.018 0.532,-0 0.495,-0L0.142,-0Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1036.16,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1047.45,1208.23)">
                                <path d="M0.244,0.01C0.215,0.01 0.185,0.007 0.153,0.001C0.121,-0.005 0.092,-0.015 0.067,-0.03C0.053,-0.038 0.044,-0.048 0.041,-0.06C0.038,-0.071 0.038,-0.082 0.043,-0.093C0.047,-0.103 0.054,-0.11 0.065,-0.115C0.075,-0.12 0.087,-0.119 0.101,-0.112C0.128,-0.099 0.153,-0.091 0.176,-0.086C0.199,-0.081 0.222,-0.078 0.245,-0.078C0.277,-0.078 0.301,-0.084 0.317,-0.095C0.332,-0.106 0.34,-0.12 0.34,-0.138C0.34,-0.166 0.319,-0.184 0.278,-0.192L0.178,-0.211C0.093,-0.227 0.051,-0.271 0.051,-0.343C0.051,-0.375 0.06,-0.403 0.078,-0.426C0.095,-0.449 0.12,-0.467 0.151,-0.48C0.182,-0.493 0.218,-0.499 0.259,-0.499C0.318,-0.499 0.37,-0.486 0.416,-0.46C0.429,-0.453 0.436,-0.444 0.439,-0.433C0.441,-0.421 0.44,-0.41 0.435,-0.4C0.43,-0.389 0.422,-0.382 0.411,-0.378C0.4,-0.373 0.388,-0.374 0.374,-0.381C0.353,-0.392 0.334,-0.399 0.315,-0.404C0.296,-0.409 0.277,-0.411 0.26,-0.411C0.227,-0.411 0.203,-0.405 0.188,-0.394C0.172,-0.383 0.164,-0.368 0.164,-0.349C0.164,-0.32 0.183,-0.302 0.221,-0.295L0.321,-0.276C0.364,-0.268 0.397,-0.253 0.42,-0.232C0.442,-0.211 0.453,-0.182 0.453,-0.146C0.453,-0.097 0.434,-0.059 0.396,-0.032C0.358,-0.004 0.307,0.01 0.244,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1057.61,1208.23)">
                                <path d="M0.291,0.01C0.24,0.01 0.195,-0.001 0.157,-0.022C0.119,-0.043 0.09,-0.072 0.069,-0.111C0.048,-0.15 0.038,-0.195 0.038,-0.247C0.038,-0.299 0.048,-0.344 0.069,-0.382C0.09,-0.419 0.119,-0.448 0.157,-0.469C0.195,-0.489 0.24,-0.499 0.291,-0.499C0.313,-0.499 0.337,-0.496 0.363,-0.49C0.388,-0.484 0.412,-0.474 0.435,-0.46C0.448,-0.452 0.456,-0.442 0.459,-0.429C0.461,-0.416 0.459,-0.405 0.454,-0.394C0.448,-0.383 0.44,-0.376 0.429,-0.371C0.418,-0.366 0.405,-0.368 0.391,-0.376C0.36,-0.393 0.331,-0.402 0.302,-0.402C0.259,-0.402 0.225,-0.389 0.202,-0.362C0.178,-0.335 0.166,-0.296 0.166,-0.246C0.166,-0.197 0.178,-0.158 0.202,-0.13C0.225,-0.101 0.259,-0.087 0.302,-0.087C0.331,-0.087 0.36,-0.095 0.391,-0.112C0.405,-0.12 0.417,-0.122 0.428,-0.117C0.439,-0.112 0.446,-0.105 0.451,-0.094C0.456,-0.083 0.457,-0.071 0.454,-0.059C0.451,-0.046 0.444,-0.036 0.431,-0.028C0.409,-0.015 0.386,-0.005 0.361,0.001C0.336,0.007 0.313,0.01 0.291,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1067.45,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.418C0.207,-0.445 0.229,-0.465 0.257,-0.479C0.285,-0.492 0.316,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1079.64,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,-1.00643,11.8244)">
                            <g transform="matrix(20.8333,0,0,20.8333,1261.44,1531.79)">
                                <path d="M0.32,0.01C0.275,0.01 0.232,0.005 0.19,-0.005C0.147,-0.015 0.11,-0.03 0.079,-0.05C0.064,-0.06 0.054,-0.072 0.051,-0.087C0.048,-0.102 0.049,-0.116 0.055,-0.129C0.06,-0.141 0.069,-0.151 0.082,-0.156C0.094,-0.161 0.108,-0.158 0.124,-0.148C0.153,-0.131 0.184,-0.118 0.217,-0.11C0.25,-0.102 0.284,-0.098 0.32,-0.098C0.373,-0.098 0.411,-0.107 0.435,-0.125C0.459,-0.142 0.471,-0.165 0.471,-0.193C0.471,-0.216 0.463,-0.235 0.446,-0.248C0.428,-0.261 0.399,-0.272 0.358,-0.281L0.248,-0.304C0.123,-0.331 0.06,-0.395 0.06,-0.497C0.06,-0.541 0.072,-0.579 0.095,-0.612C0.118,-0.645 0.151,-0.67 0.192,-0.688C0.233,-0.706 0.281,-0.715 0.335,-0.715C0.375,-0.715 0.413,-0.71 0.449,-0.7C0.485,-0.69 0.517,-0.675 0.546,-0.656C0.559,-0.647 0.567,-0.635 0.57,-0.622C0.573,-0.608 0.571,-0.595 0.565,-0.584C0.559,-0.572 0.55,-0.564 0.537,-0.56C0.524,-0.555 0.508,-0.558 0.491,-0.568C0.466,-0.582 0.441,-0.592 0.416,-0.598C0.391,-0.604 0.363,-0.607 0.334,-0.607C0.288,-0.607 0.252,-0.597 0.226,-0.578C0.2,-0.559 0.187,-0.533 0.187,-0.502C0.187,-0.478 0.195,-0.459 0.211,-0.445C0.227,-0.43 0.254,-0.419 0.293,-0.411L0.402,-0.388C0.468,-0.374 0.517,-0.352 0.55,-0.322C0.582,-0.292 0.598,-0.252 0.598,-0.202C0.598,-0.16 0.587,-0.123 0.564,-0.091C0.541,-0.059 0.509,-0.034 0.468,-0.017C0.426,0.001 0.377,0.01 0.32,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1274.59,1531.79)">
                                <path d="M0.142,-0C0.099,-0 0.077,-0.022 0.077,-0.066L0.077,-0.643C0.077,-0.686 0.098,-0.708 0.141,-0.708C0.184,-0.708 0.205,-0.686 0.205,-0.643L0.205,-0.109L0.495,-0.109C0.532,-0.109 0.551,-0.091 0.551,-0.055C0.551,-0.018 0.532,-0 0.495,-0L0.142,-0Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1285.71,1531.79)">
                                <path d="M0.37,0.01C0.273,0.01 0.2,-0.015 0.149,-0.066C0.097,-0.116 0.072,-0.19 0.072,-0.289L0.072,-0.648C0.072,-0.691 0.093,-0.713 0.136,-0.713C0.179,-0.713 0.2,-0.691 0.2,-0.648L0.2,-0.29C0.2,-0.227 0.215,-0.179 0.244,-0.148C0.273,-0.116 0.315,-0.1 0.37,-0.1C0.483,-0.1 0.539,-0.163 0.539,-0.29L0.539,-0.648C0.539,-0.691 0.56,-0.713 0.603,-0.713C0.645,-0.713 0.666,-0.691 0.666,-0.648L0.666,-0.289C0.666,-0.19 0.641,-0.116 0.591,-0.066C0.541,-0.015 0.467,0.01 0.37,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,18.2785,1.12776)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.145,-0C0.1,-0 0.077,-0.023 0.077,-0.068L0.077,-0.637C0.077,-0.682 0.1,-0.705 0.145,-0.705L0.385,-0.705C0.459,-0.705 0.516,-0.689 0.557,-0.657C0.598,-0.624 0.618,-0.579 0.618,-0.522C0.618,-0.484 0.608,-0.451 0.589,-0.424C0.57,-0.397 0.543,-0.377 0.509,-0.364C0.549,-0.353 0.58,-0.333 0.603,-0.305C0.625,-0.276 0.636,-0.24 0.636,-0.197C0.636,-0.134 0.615,-0.086 0.573,-0.052C0.53,-0.017 0.471,-0 0.396,-0L0.145,-0ZM0.2,-0.406L0.363,-0.406C0.45,-0.406 0.494,-0.44 0.494,-0.507C0.494,-0.574 0.45,-0.608 0.363,-0.608L0.2,-0.608L0.2,-0.406ZM0.2,-0.097L0.38,-0.097C0.468,-0.097 0.512,-0.132 0.512,-0.203C0.512,-0.274 0.468,-0.309 0.38,-0.309L0.2,-0.309L0.2,-0.097Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1038.89,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1050.28,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1056.93,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1063.57,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1074.97,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1082.97,1208.23)">
                                <path d="M0.253,0.01C0.21,0.01 0.173,-0 0.141,-0.021C0.108,-0.042 0.083,-0.071 0.065,-0.11C0.047,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.298 0.047,-0.343 0.065,-0.381C0.083,-0.418 0.108,-0.447 0.141,-0.468C0.173,-0.489 0.21,-0.499 0.253,-0.499C0.288,-0.499 0.319,-0.491 0.347,-0.476C0.375,-0.461 0.396,-0.44 0.41,-0.415L0.41,-0.65C0.41,-0.692 0.431,-0.713 0.472,-0.713C0.514,-0.713 0.535,-0.692 0.535,-0.65L0.535,-0.056C0.535,-0.013 0.514,0.008 0.473,0.008C0.432,0.008 0.411,-0.013 0.411,-0.056L0.411,-0.077C0.397,-0.05 0.376,-0.029 0.348,-0.014C0.32,0.002 0.288,0.01 0.253,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,-57.0974,165.335)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.137,0.008C0.098,0.008 0.079,-0.012 0.079,-0.052L0.079,-0.653C0.079,-0.693 0.099,-0.713 0.139,-0.713C0.156,-0.713 0.169,-0.71 0.179,-0.704C0.188,-0.697 0.197,-0.686 0.205,-0.671L0.436,-0.244L0.667,-0.671C0.675,-0.686 0.684,-0.697 0.693,-0.704C0.702,-0.71 0.715,-0.713 0.732,-0.713C0.771,-0.713 0.79,-0.693 0.79,-0.653L0.79,-0.052C0.79,-0.012 0.771,0.008 0.732,0.008C0.693,0.008 0.674,-0.012 0.674,-0.052L0.674,-0.475L0.486,-0.132C0.479,-0.121 0.472,-0.112 0.465,-0.107C0.458,-0.102 0.448,-0.099 0.435,-0.099C0.422,-0.099 0.412,-0.102 0.404,-0.108C0.396,-0.113 0.389,-0.121 0.383,-0.132L0.195,-0.473L0.195,-0.052C0.195,-0.012 0.176,0.008 0.137,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1042.64,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1054.03,1208.23)">
                                <path d="M0.293,0.19C0.257,0.19 0.222,0.187 0.189,0.18C0.155,0.173 0.124,0.163 0.097,0.149C0.081,0.141 0.071,0.131 0.069,0.119C0.066,0.106 0.067,0.094 0.072,0.083C0.077,0.072 0.086,0.064 0.097,0.059C0.108,0.054 0.119,0.054 0.132,0.06C0.161,0.075 0.189,0.084 0.216,0.089C0.242,0.093 0.264,0.095 0.283,0.095C0.372,0.095 0.417,0.052 0.417,-0.034L0.417,-0.101C0.403,-0.074 0.382,-0.052 0.353,-0.036C0.323,-0.02 0.291,-0.012 0.256,-0.012C0.212,-0.012 0.174,-0.022 0.141,-0.043C0.108,-0.063 0.083,-0.091 0.065,-0.128C0.047,-0.165 0.038,-0.207 0.038,-0.256C0.038,-0.305 0.047,-0.347 0.065,-0.384C0.083,-0.42 0.108,-0.448 0.141,-0.469C0.174,-0.489 0.212,-0.499 0.256,-0.499C0.291,-0.499 0.323,-0.491 0.352,-0.476C0.381,-0.461 0.402,-0.44 0.415,-0.413L0.415,-0.434C0.415,-0.455 0.421,-0.47 0.432,-0.481C0.443,-0.492 0.458,-0.497 0.477,-0.497C0.518,-0.497 0.539,-0.476 0.539,-0.434L0.539,-0.049C0.539,0.03 0.518,0.09 0.476,0.13C0.434,0.17 0.373,0.19 0.293,0.19ZM0.29,-0.107C0.329,-0.107 0.359,-0.12 0.382,-0.147C0.405,-0.174 0.416,-0.21 0.416,-0.256C0.416,-0.302 0.405,-0.338 0.382,-0.365C0.359,-0.391 0.329,-0.404 0.29,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.302 0.164,-0.256C0.164,-0.21 0.175,-0.174 0.198,-0.147C0.221,-0.12 0.251,-0.107 0.29,-0.107Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1066.61,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1078.8,1208.23)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1090.8,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1097.45,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1102.76,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,104.442,235.606)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.663,0.113C0.674,0.131 0.678,0.147 0.673,0.162C0.668,0.176 0.658,0.187 0.644,0.194C0.629,0.201 0.614,0.202 0.598,0.199C0.581,0.196 0.567,0.185 0.556,0.167L0.487,0.056C0.468,0.025 0.437,0.01 0.392,0.01C0.324,0.01 0.265,-0.005 0.214,-0.035C0.163,-0.065 0.124,-0.107 0.096,-0.162C0.068,-0.216 0.054,-0.28 0.054,-0.353C0.054,-0.427 0.068,-0.491 0.096,-0.545C0.124,-0.599 0.163,-0.641 0.214,-0.671C0.265,-0.7 0.324,-0.715 0.393,-0.715C0.462,-0.715 0.521,-0.7 0.572,-0.671C0.623,-0.641 0.662,-0.599 0.69,-0.545C0.718,-0.491 0.732,-0.427 0.732,-0.354C0.732,-0.274 0.716,-0.205 0.683,-0.148C0.65,-0.091 0.604,-0.049 0.546,-0.022C0.561,-0.017 0.574,-0.009 0.586,0.002C0.597,0.013 0.609,0.027 0.62,0.045L0.663,0.113ZM0.393,-0.1C0.458,-0.1 0.508,-0.122 0.544,-0.167C0.58,-0.212 0.598,-0.274 0.598,-0.353C0.598,-0.433 0.58,-0.495 0.545,-0.539C0.509,-0.583 0.458,-0.605 0.393,-0.605C0.328,-0.605 0.278,-0.583 0.242,-0.539C0.206,-0.495 0.188,-0.433 0.188,-0.353C0.188,-0.274 0.206,-0.212 0.242,-0.167C0.278,-0.122 0.328,-0.1 0.393,-0.1Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1040.91,1208.23)">
                                <path d="M0.242,0.01C0.122,0.01 0.062,-0.057 0.062,-0.192L0.062,-0.434C0.062,-0.476 0.083,-0.497 0.124,-0.497C0.166,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.19C0.187,-0.155 0.194,-0.13 0.208,-0.113C0.222,-0.096 0.244,-0.088 0.275,-0.088C0.308,-0.088 0.336,-0.099 0.357,-0.123C0.378,-0.146 0.389,-0.176 0.389,-0.214L0.389,-0.434C0.389,-0.476 0.41,-0.497 0.451,-0.497C0.493,-0.497 0.514,-0.476 0.514,-0.434L0.514,-0.056C0.514,-0.013 0.494,0.008 0.453,0.008C0.412,0.008 0.392,-0.013 0.392,-0.056L0.392,-0.073C0.359,-0.018 0.309,0.01 0.242,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1052.97,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1064.26,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1075.55,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1093.39,1208.23)">
                                <path d="M0.084,0.008C0.061,0.008 0.044,0 0.034,-0.016C0.024,-0.032 0.024,-0.051 0.035,-0.074L0.299,-0.659C0.308,-0.678 0.318,-0.692 0.331,-0.701C0.343,-0.709 0.357,-0.713 0.373,-0.713C0.388,-0.713 0.402,-0.709 0.415,-0.701C0.427,-0.692 0.437,-0.678 0.446,-0.659L0.711,-0.074C0.722,-0.051 0.722,-0.031 0.713,-0.016C0.704,0 0.688,0.008 0.665,0.008C0.646,0.008 0.632,0.004 0.622,-0.006C0.611,-0.014 0.602,-0.028 0.594,-0.047L0.544,-0.163L0.2,-0.163L0.151,-0.047C0.142,-0.028 0.133,-0.014 0.124,-0.005C0.115,0.004 0.101,0.008 0.084,0.008ZM0.371,-0.566L0.244,-0.265L0.5,-0.265L0.373,-0.566L0.371,-0.566Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1108.89,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1121.07,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1133.26,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,135.953,309.131)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.142,-0C0.099,-0 0.077,-0.022 0.077,-0.066L0.077,-0.643C0.077,-0.686 0.098,-0.708 0.141,-0.708C0.184,-0.708 0.205,-0.686 0.205,-0.643L0.205,-0.109L0.495,-0.109C0.532,-0.109 0.551,-0.091 0.551,-0.055C0.551,-0.018 0.532,-0 0.495,-0L0.142,-0Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1036.16,1208.23)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1047.95,1208.23)">
                                <path d="M0.254,0.008C0.219,0.008 0.195,-0.009 0.182,-0.044L0.037,-0.414C0.028,-0.437 0.029,-0.457 0.039,-0.473C0.048,-0.489 0.065,-0.497 0.09,-0.497C0.105,-0.497 0.117,-0.494 0.126,-0.487C0.135,-0.48 0.143,-0.466 0.15,-0.447L0.26,-0.146L0.373,-0.455C0.384,-0.483 0.403,-0.497 0.43,-0.497C0.458,-0.497 0.477,-0.483 0.487,-0.455L0.6,-0.143L0.711,-0.45C0.718,-0.468 0.726,-0.48 0.737,-0.487C0.747,-0.494 0.758,-0.497 0.771,-0.497C0.795,-0.497 0.811,-0.489 0.818,-0.472C0.825,-0.455 0.825,-0.435 0.816,-0.414L0.672,-0.044C0.658,-0.009 0.634,0.008 0.6,0.008C0.565,0.008 0.541,-0.009 0.528,-0.044L0.426,-0.315L0.326,-0.045C0.313,-0.01 0.289,0.008 0.254,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1065.51,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1076.8,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1229.07)">
                                <path d="M0.663,0.113C0.674,0.131 0.678,0.147 0.673,0.162C0.668,0.176 0.658,0.187 0.644,0.194C0.629,0.201 0.614,0.202 0.598,0.199C0.581,0.196 0.567,0.185 0.556,0.167L0.487,0.056C0.468,0.025 0.437,0.01 0.392,0.01C0.324,0.01 0.265,-0.005 0.214,-0.035C0.163,-0.065 0.124,-0.107 0.096,-0.162C0.068,-0.216 0.054,-0.28 0.054,-0.353C0.054,-0.427 0.068,-0.491 0.096,-0.545C0.124,-0.599 0.163,-0.641 0.214,-0.671C0.265,-0.7 0.324,-0.715 0.393,-0.715C0.462,-0.715 0.521,-0.7 0.572,-0.671C0.623,-0.641 0.662,-0.599 0.69,-0.545C0.718,-0.491 0.732,-0.427 0.732,-0.354C0.732,-0.274 0.716,-0.205 0.683,-0.148C0.65,-0.091 0.604,-0.049 0.546,-0.022C0.561,-0.017 0.574,-0.009 0.586,0.002C0.597,0.013 0.609,0.027 0.62,0.045L0.663,0.113ZM0.393,-0.1C0.458,-0.1 0.508,-0.122 0.544,-0.167C0.58,-0.212 0.598,-0.274 0.598,-0.353C0.598,-0.433 0.58,-0.495 0.545,-0.539C0.509,-0.583 0.458,-0.605 0.393,-0.605C0.328,-0.605 0.278,-0.583 0.242,-0.539C0.206,-0.495 0.188,-0.433 0.188,-0.353C0.188,-0.274 0.206,-0.212 0.242,-0.167C0.278,-0.122 0.328,-0.1 0.393,-0.1Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1040.59,1229.07)">
                                <path d="M0.084,0.008C0.061,0.008 0.044,0 0.034,-0.016C0.024,-0.032 0.024,-0.051 0.035,-0.074L0.299,-0.659C0.308,-0.678 0.318,-0.692 0.331,-0.701C0.343,-0.709 0.357,-0.713 0.373,-0.713C0.388,-0.713 0.402,-0.709 0.415,-0.701C0.427,-0.692 0.437,-0.678 0.446,-0.659L0.711,-0.074C0.722,-0.051 0.722,-0.031 0.713,-0.016C0.704,0 0.688,0.008 0.665,0.008C0.646,0.008 0.632,0.004 0.622,-0.006C0.611,-0.014 0.602,-0.028 0.594,-0.047L0.544,-0.163L0.2,-0.163L0.151,-0.047C0.142,-0.028 0.133,-0.014 0.124,-0.005C0.115,0.004 0.101,0.008 0.084,0.008ZM0.371,-0.566L0.244,-0.265L0.5,-0.265L0.373,-0.566L0.371,-0.566Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(0.0266168,-0.999646,0.999646,0.0266168,-132.263,2437.43)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.141,0.008C0.098,0.008 0.077,-0.014 0.077,-0.058L0.077,-0.647C0.077,-0.691 0.098,-0.713 0.141,-0.713C0.184,-0.713 0.205,-0.691 0.205,-0.647L0.205,-0.058C0.205,-0.014 0.184,0.008 0.141,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1030.43,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1042.61,1208.23)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1050.51,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1061.8,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1069.97,1208.23)">
                                <path d="M0.347,0.01C0.311,0.01 0.279,0.002 0.251,-0.014C0.223,-0.029 0.202,-0.05 0.188,-0.077L0.188,-0.056C0.188,-0.013 0.168,0.008 0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.417C0.205,-0.442 0.226,-0.462 0.254,-0.477C0.281,-0.492 0.312,-0.499 0.347,-0.499C0.39,-0.499 0.428,-0.489 0.46,-0.468C0.492,-0.447 0.517,-0.418 0.535,-0.381C0.553,-0.343 0.562,-0.298 0.562,-0.245C0.562,-0.193 0.553,-0.148 0.535,-0.11C0.517,-0.071 0.492,-0.042 0.46,-0.021C0.427,-0 0.39,0.01 0.347,0.01ZM0.312,-0.085C0.349,-0.085 0.379,-0.098 0.402,-0.126C0.425,-0.153 0.436,-0.192 0.436,-0.245C0.436,-0.298 0.425,-0.338 0.402,-0.365C0.379,-0.391 0.349,-0.404 0.312,-0.404C0.275,-0.404 0.245,-0.391 0.222,-0.365C0.199,-0.338 0.188,-0.298 0.188,-0.245C0.188,-0.192 0.199,-0.153 0.222,-0.126C0.245,-0.098 0.275,-0.085 0.312,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1082.47,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1093.76,1208.23)">
                                <path d="M0.189,0.188C0.166,0.188 0.151,0.18 0.142,0.163C0.133,0.146 0.134,0.127 0.143,0.106L0.2,-0.02L0.029,-0.414C0.02,-0.436 0.02,-0.455 0.029,-0.472C0.038,-0.489 0.057,-0.497 0.084,-0.497C0.099,-0.497 0.112,-0.494 0.121,-0.487C0.13,-0.48 0.139,-0.466 0.146,-0.447L0.266,-0.147L0.389,-0.448C0.396,-0.467 0.405,-0.48 0.415,-0.487C0.425,-0.494 0.438,-0.497 0.455,-0.497C0.476,-0.497 0.491,-0.489 0.5,-0.472C0.509,-0.455 0.508,-0.436 0.499,-0.415L0.256,0.14C0.247,0.159 0.238,0.171 0.228,0.178C0.218,0.185 0.205,0.188 0.189,0.188Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,296.562,317.256)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.405,0.01C0.332,0.01 0.269,-0.005 0.217,-0.035C0.164,-0.064 0.124,-0.106 0.096,-0.161C0.068,-0.215 0.054,-0.279 0.054,-0.353C0.054,-0.427 0.068,-0.491 0.096,-0.545C0.124,-0.599 0.164,-0.641 0.217,-0.671C0.269,-0.7 0.332,-0.715 0.405,-0.715C0.442,-0.715 0.478,-0.71 0.513,-0.701C0.548,-0.692 0.579,-0.678 0.606,-0.661C0.623,-0.65 0.634,-0.636 0.637,-0.621C0.64,-0.605 0.638,-0.591 0.631,-0.578C0.624,-0.565 0.613,-0.556 0.598,-0.552C0.583,-0.547 0.567,-0.551 0.549,-0.563C0.528,-0.576 0.506,-0.586 0.482,-0.592C0.458,-0.598 0.434,-0.601 0.409,-0.601C0.338,-0.601 0.283,-0.58 0.246,-0.537C0.209,-0.494 0.19,-0.432 0.19,-0.353C0.19,-0.274 0.209,-0.212 0.246,-0.169C0.283,-0.126 0.338,-0.104 0.409,-0.104C0.434,-0.104 0.459,-0.107 0.484,-0.114C0.509,-0.12 0.532,-0.13 0.554,-0.143C0.572,-0.154 0.588,-0.158 0.602,-0.154C0.616,-0.15 0.627,-0.142 0.634,-0.13C0.641,-0.117 0.643,-0.104 0.64,-0.089C0.637,-0.074 0.628,-0.061 0.613,-0.05C0.586,-0.031 0.555,-0.016 0.518,-0.006C0.481,0.005 0.443,0.01 0.405,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1038.34,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1049.74,1208.23)">
                                <path d="M0.127,0.188C0.086,0.188 0.065,0.167 0.065,0.125L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.188,-0.476 0.188,-0.434L0.188,-0.413C0.202,-0.439 0.223,-0.46 0.251,-0.476C0.279,-0.491 0.311,-0.499 0.347,-0.499C0.39,-0.499 0.427,-0.489 0.46,-0.468C0.492,-0.447 0.517,-0.418 0.535,-0.381C0.553,-0.343 0.562,-0.298 0.562,-0.245C0.562,-0.193 0.553,-0.148 0.535,-0.11C0.517,-0.071 0.492,-0.042 0.46,-0.021C0.428,-0 0.39,0.01 0.347,0.01C0.312,0.01 0.281,0.003 0.254,-0.013C0.226,-0.028 0.205,-0.048 0.19,-0.073L0.19,0.125C0.19,0.167 0.169,0.188 0.127,0.188ZM0.312,-0.085C0.349,-0.085 0.379,-0.098 0.402,-0.126C0.425,-0.153 0.436,-0.192 0.436,-0.245C0.436,-0.298 0.425,-0.338 0.402,-0.365C0.379,-0.391 0.349,-0.404 0.312,-0.404C0.275,-0.404 0.245,-0.391 0.222,-0.365C0.199,-0.338 0.188,-0.298 0.188,-0.245C0.188,-0.192 0.199,-0.153 0.222,-0.126C0.245,-0.098 0.275,-0.085 0.312,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1062.24,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1067.55,1208.23)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1075.45,1208.23)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1087.45,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1099.74,1208.23)">
                                <path d="M0.141,0.008C0.098,0.008 0.077,-0.014 0.077,-0.058L0.077,-0.647C0.077,-0.691 0.098,-0.713 0.141,-0.713C0.184,-0.713 0.205,-0.691 0.205,-0.647L0.205,-0.411L0.568,-0.411L0.568,-0.647C0.568,-0.691 0.589,-0.713 0.632,-0.713C0.675,-0.713 0.696,-0.691 0.696,-0.647L0.696,-0.058C0.696,-0.014 0.675,0.008 0.632,0.008C0.589,0.008 0.568,-0.014 0.568,-0.058L0.568,-0.304L0.205,-0.304L0.205,-0.058C0.205,-0.014 0.184,0.008 0.141,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1115.84,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1121.16,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1127.8,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(0.0494179,-0.998778,0.998778,0.0494179,67.0976,2443.85)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.145,-0C0.1,-0 0.077,-0.023 0.077,-0.068L0.077,-0.637C0.077,-0.682 0.1,-0.705 0.145,-0.705L0.502,-0.705C0.537,-0.705 0.554,-0.688 0.554,-0.655C0.554,-0.62 0.537,-0.603 0.502,-0.603L0.2,-0.603L0.2,-0.409L0.48,-0.409C0.515,-0.409 0.532,-0.392 0.532,-0.358C0.532,-0.324 0.515,-0.307 0.48,-0.307L0.2,-0.307L0.2,-0.102L0.502,-0.102C0.537,-0.102 0.554,-0.085 0.554,-0.051C0.554,-0.017 0.537,-0 0.502,-0L0.145,-0Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1036.89,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1048.28,1208.23)">
                                <path d="M0.244,0.01C0.215,0.01 0.185,0.007 0.153,0.001C0.121,-0.005 0.092,-0.015 0.067,-0.03C0.053,-0.038 0.044,-0.048 0.041,-0.06C0.038,-0.071 0.038,-0.082 0.043,-0.093C0.047,-0.103 0.054,-0.11 0.065,-0.115C0.075,-0.12 0.087,-0.119 0.101,-0.112C0.128,-0.099 0.153,-0.091 0.176,-0.086C0.199,-0.081 0.222,-0.078 0.245,-0.078C0.277,-0.078 0.301,-0.084 0.317,-0.095C0.332,-0.106 0.34,-0.12 0.34,-0.138C0.34,-0.166 0.319,-0.184 0.278,-0.192L0.178,-0.211C0.093,-0.227 0.051,-0.271 0.051,-0.343C0.051,-0.375 0.06,-0.403 0.078,-0.426C0.095,-0.449 0.12,-0.467 0.151,-0.48C0.182,-0.493 0.218,-0.499 0.259,-0.499C0.318,-0.499 0.37,-0.486 0.416,-0.46C0.429,-0.453 0.436,-0.444 0.439,-0.433C0.441,-0.421 0.44,-0.41 0.435,-0.4C0.43,-0.389 0.422,-0.382 0.411,-0.378C0.4,-0.373 0.388,-0.374 0.374,-0.381C0.353,-0.392 0.334,-0.399 0.315,-0.404C0.296,-0.409 0.277,-0.411 0.26,-0.411C0.227,-0.411 0.203,-0.405 0.188,-0.394C0.172,-0.383 0.164,-0.368 0.164,-0.349C0.164,-0.32 0.183,-0.302 0.221,-0.295L0.321,-0.276C0.364,-0.268 0.397,-0.253 0.42,-0.232C0.442,-0.211 0.453,-0.182 0.453,-0.146C0.453,-0.097 0.434,-0.059 0.396,-0.032C0.358,-0.004 0.307,0.01 0.244,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1058.34,1208.23)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1066.61,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1073.26,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1084.66,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.281L0.192,-0.281L0.357,-0.455C0.37,-0.468 0.382,-0.479 0.392,-0.486C0.402,-0.493 0.416,-0.497 0.433,-0.497C0.45,-0.497 0.464,-0.493 0.473,-0.484C0.482,-0.474 0.486,-0.464 0.486,-0.451C0.486,-0.438 0.48,-0.426 0.468,-0.413L0.318,-0.255L0.485,-0.075C0.497,-0.062 0.503,-0.049 0.502,-0.037C0.501,-0.024 0.495,-0.013 0.485,-0.005C0.475,0.004 0.462,0.008 0.447,0.008C0.428,0.008 0.413,0.004 0.403,-0.003C0.392,-0.01 0.379,-0.021 0.366,-0.036L0.192,-0.218L0.19,-0.218L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1095.61,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(0.569346,0.822098,-0.822098,0.569346,1648.52,64.4751)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.145,-0C0.1,-0 0.077,-0.023 0.077,-0.068L0.077,-0.637C0.077,-0.682 0.1,-0.705 0.145,-0.705L0.339,-0.705C0.457,-0.705 0.548,-0.675 0.613,-0.614C0.677,-0.553 0.709,-0.466 0.709,-0.353C0.709,-0.24 0.677,-0.153 0.613,-0.092C0.548,-0.031 0.457,-0 0.339,-0L0.145,-0ZM0.205,-0.106L0.331,-0.106C0.494,-0.106 0.575,-0.188 0.575,-0.353C0.575,-0.517 0.494,-0.599 0.331,-0.599L0.205,-0.599L0.205,-0.106Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1040.43,1208.23)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1052.22,1208.23)">
                                <path d="M0.254,0.008C0.219,0.008 0.195,-0.009 0.182,-0.044L0.037,-0.414C0.028,-0.437 0.029,-0.457 0.039,-0.473C0.048,-0.489 0.065,-0.497 0.09,-0.497C0.105,-0.497 0.117,-0.494 0.126,-0.487C0.135,-0.48 0.143,-0.466 0.15,-0.447L0.26,-0.146L0.373,-0.455C0.384,-0.483 0.403,-0.497 0.43,-0.497C0.458,-0.497 0.477,-0.483 0.487,-0.455L0.6,-0.143L0.711,-0.45C0.718,-0.468 0.726,-0.48 0.737,-0.487C0.747,-0.494 0.758,-0.497 0.771,-0.497C0.795,-0.497 0.811,-0.489 0.818,-0.472C0.825,-0.455 0.825,-0.435 0.816,-0.414L0.672,-0.044C0.658,-0.009 0.634,0.008 0.6,0.008C0.565,0.008 0.541,-0.009 0.528,-0.044L0.426,-0.315L0.326,-0.045C0.313,-0.01 0.289,0.008 0.254,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1069.99,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1082.18,1208.23)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1090.07,1208.23)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1101.86,1208.23)">
                                <path d="M0.254,0.008C0.219,0.008 0.195,-0.009 0.182,-0.044L0.037,-0.414C0.028,-0.437 0.029,-0.457 0.039,-0.473C0.048,-0.489 0.065,-0.497 0.09,-0.497C0.105,-0.497 0.117,-0.494 0.126,-0.487C0.135,-0.48 0.143,-0.466 0.15,-0.447L0.26,-0.146L0.373,-0.455C0.384,-0.483 0.403,-0.497 0.43,-0.497C0.458,-0.497 0.477,-0.483 0.487,-0.455L0.6,-0.143L0.711,-0.45C0.718,-0.468 0.726,-0.48 0.737,-0.487C0.747,-0.494 0.758,-0.497 0.771,-0.497C0.795,-0.497 0.811,-0.489 0.818,-0.472C0.825,-0.455 0.825,-0.435 0.816,-0.414L0.672,-0.044C0.658,-0.009 0.634,0.008 0.6,0.008C0.565,0.008 0.541,-0.009 0.528,-0.044L0.426,-0.315L0.326,-0.045C0.313,-0.01 0.289,0.008 0.254,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1119.64,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(6.12323e-17,-1,1,6.12323e-17,-9.49905,2294.77)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.141,0.008C0.098,0.008 0.077,-0.014 0.077,-0.058L0.077,-0.64C0.077,-0.683 0.099,-0.705 0.142,-0.705L0.383,-0.705C0.46,-0.705 0.52,-0.686 0.562,-0.647C0.604,-0.608 0.625,-0.554 0.625,-0.485C0.625,-0.416 0.604,-0.362 0.562,-0.323C0.52,-0.284 0.46,-0.264 0.383,-0.264L0.205,-0.264L0.205,-0.058C0.205,-0.014 0.184,0.008 0.141,0.008ZM0.205,-0.365L0.363,-0.365C0.455,-0.365 0.501,-0.405 0.501,-0.485C0.501,-0.564 0.455,-0.604 0.363,-0.604L0.205,-0.604L0.205,-0.365Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1038.14,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.418C0.207,-0.445 0.229,-0.465 0.257,-0.479C0.285,-0.492 0.316,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1050.32,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1055.64,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1067.82,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1080.01,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1091.14,1208.23)">
                                <path d="M0.189,0.188C0.166,0.188 0.151,0.18 0.142,0.163C0.133,0.146 0.134,0.127 0.143,0.106L0.2,-0.02L0.029,-0.414C0.02,-0.436 0.02,-0.455 0.029,-0.472C0.038,-0.489 0.057,-0.497 0.084,-0.497C0.099,-0.497 0.112,-0.494 0.121,-0.487C0.13,-0.48 0.139,-0.466 0.146,-0.447L0.266,-0.147L0.389,-0.448C0.396,-0.467 0.405,-0.48 0.415,-0.487C0.425,-0.494 0.438,-0.497 0.455,-0.497C0.476,-0.497 0.491,-0.489 0.5,-0.472C0.509,-0.455 0.508,-0.436 0.499,-0.415L0.256,0.14C0.247,0.159 0.238,0.171 0.228,0.178C0.218,0.185 0.205,0.188 0.189,0.188Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1229.07)">
                                <path d="M0.141,0.008C0.098,0.008 0.077,-0.014 0.077,-0.058L0.077,-0.64C0.077,-0.683 0.099,-0.705 0.142,-0.705L0.383,-0.705C0.46,-0.705 0.52,-0.687 0.562,-0.65C0.604,-0.612 0.625,-0.561 0.625,-0.494C0.625,-0.441 0.611,-0.398 0.584,-0.364C0.557,-0.329 0.517,-0.306 0.466,-0.294C0.5,-0.283 0.528,-0.258 0.55,-0.217L0.625,-0.078C0.636,-0.057 0.639,-0.037 0.632,-0.019C0.624,-0.001 0.607,0.008 0.58,0.008C0.562,0.008 0.547,0.004 0.536,-0.004C0.525,-0.011 0.515,-0.023 0.506,-0.039L0.405,-0.225C0.393,-0.248 0.379,-0.263 0.363,-0.271C0.346,-0.278 0.325,-0.282 0.3,-0.282L0.205,-0.282L0.205,-0.058C0.205,-0.014 0.184,0.008 0.141,0.008ZM0.205,-0.377L0.361,-0.377C0.454,-0.377 0.501,-0.415 0.501,-0.491C0.501,-0.566 0.454,-0.604 0.361,-0.604L0.205,-0.604L0.205,-0.377Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1038.84,1229.07)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1044.16,1229.07)">
                                <path d="M0.253,0.01C0.21,0.01 0.173,-0 0.141,-0.021C0.108,-0.042 0.083,-0.071 0.065,-0.11C0.047,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.298 0.047,-0.343 0.065,-0.381C0.083,-0.418 0.108,-0.447 0.141,-0.468C0.173,-0.489 0.21,-0.499 0.253,-0.499C0.288,-0.499 0.319,-0.491 0.347,-0.476C0.375,-0.461 0.396,-0.44 0.41,-0.415L0.41,-0.65C0.41,-0.692 0.431,-0.713 0.472,-0.713C0.514,-0.713 0.535,-0.692 0.535,-0.65L0.535,-0.056C0.535,-0.013 0.514,0.008 0.473,0.008C0.432,0.008 0.411,-0.013 0.411,-0.056L0.411,-0.077C0.397,-0.05 0.376,-0.029 0.348,-0.014C0.32,0.002 0.288,0.01 0.253,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1056.66,1229.07)">
                                <path d="M0.293,0.19C0.257,0.19 0.222,0.187 0.189,0.18C0.155,0.173 0.124,0.163 0.097,0.149C0.081,0.141 0.071,0.131 0.069,0.119C0.066,0.106 0.067,0.094 0.072,0.083C0.077,0.072 0.086,0.064 0.097,0.059C0.108,0.054 0.119,0.054 0.132,0.06C0.161,0.075 0.189,0.084 0.216,0.089C0.242,0.093 0.264,0.095 0.283,0.095C0.372,0.095 0.417,0.052 0.417,-0.034L0.417,-0.101C0.403,-0.074 0.382,-0.052 0.353,-0.036C0.323,-0.02 0.291,-0.012 0.256,-0.012C0.212,-0.012 0.174,-0.022 0.141,-0.043C0.108,-0.063 0.083,-0.091 0.065,-0.128C0.047,-0.165 0.038,-0.207 0.038,-0.256C0.038,-0.305 0.047,-0.347 0.065,-0.384C0.083,-0.42 0.108,-0.448 0.141,-0.469C0.174,-0.489 0.212,-0.499 0.256,-0.499C0.291,-0.499 0.323,-0.491 0.352,-0.476C0.381,-0.461 0.402,-0.44 0.415,-0.413L0.415,-0.434C0.415,-0.455 0.421,-0.47 0.432,-0.481C0.443,-0.492 0.458,-0.497 0.477,-0.497C0.518,-0.497 0.539,-0.476 0.539,-0.434L0.539,-0.049C0.539,0.03 0.518,0.09 0.476,0.13C0.434,0.17 0.373,0.19 0.293,0.19ZM0.29,-0.107C0.329,-0.107 0.359,-0.12 0.382,-0.147C0.405,-0.174 0.416,-0.21 0.416,-0.256C0.416,-0.302 0.405,-0.338 0.382,-0.365C0.359,-0.391 0.329,-0.404 0.29,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.302 0.164,-0.256C0.164,-0.21 0.175,-0.174 0.198,-0.147C0.221,-0.12 0.251,-0.107 0.29,-0.107Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1069.24,1229.07)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,142.911,108.287)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.143,0.008C0.099,0.008 0.077,-0.015 0.077,-0.06L0.077,-0.637C0.077,-0.682 0.1,-0.705 0.145,-0.705L0.494,-0.705C0.529,-0.705 0.546,-0.688 0.546,-0.655C0.546,-0.62 0.529,-0.603 0.494,-0.603L0.205,-0.603L0.205,-0.404L0.473,-0.404C0.508,-0.404 0.525,-0.387 0.525,-0.354C0.525,-0.319 0.508,-0.302 0.473,-0.302L0.205,-0.302L0.205,-0.06C0.205,-0.015 0.184,0.008 0.143,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1036.05,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1044.05,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1055.34,1208.23)">
                                <path d="M0.129,0.008C0.088,0.008 0.067,-0.013 0.067,-0.056L0.067,-0.434C0.067,-0.476 0.087,-0.497 0.128,-0.497C0.169,-0.497 0.189,-0.476 0.189,-0.434L0.189,-0.414C0.204,-0.441 0.224,-0.462 0.25,-0.477C0.276,-0.492 0.306,-0.499 0.34,-0.499C0.413,-0.499 0.461,-0.467 0.484,-0.403C0.499,-0.433 0.521,-0.456 0.55,-0.474C0.579,-0.491 0.611,-0.499 0.648,-0.499C0.758,-0.499 0.813,-0.432 0.813,-0.298L0.813,-0.056C0.813,-0.013 0.792,0.008 0.75,0.008C0.709,0.008 0.688,-0.013 0.688,-0.056L0.688,-0.293C0.688,-0.33 0.682,-0.358 0.67,-0.375C0.657,-0.392 0.636,-0.401 0.607,-0.401C0.574,-0.401 0.549,-0.39 0.53,-0.367C0.511,-0.343 0.502,-0.312 0.502,-0.271L0.502,-0.056C0.502,-0.013 0.481,0.008 0.44,0.008C0.398,0.008 0.377,-0.013 0.377,-0.056L0.377,-0.293C0.377,-0.33 0.371,-0.358 0.359,-0.375C0.346,-0.392 0.326,-0.401 0.297,-0.401C0.264,-0.401 0.239,-0.39 0.22,-0.367C0.201,-0.343 0.192,-0.312 0.192,-0.271L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1073.61,1208.23)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1085.61,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1097.8,1208.23)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,325.54,472.532)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.405,0.01C0.332,0.01 0.269,-0.005 0.217,-0.035C0.164,-0.064 0.124,-0.106 0.096,-0.161C0.068,-0.215 0.054,-0.279 0.054,-0.353C0.054,-0.427 0.068,-0.491 0.096,-0.545C0.124,-0.599 0.164,-0.641 0.217,-0.671C0.269,-0.7 0.332,-0.715 0.405,-0.715C0.442,-0.715 0.478,-0.71 0.513,-0.701C0.548,-0.692 0.579,-0.678 0.606,-0.661C0.623,-0.65 0.634,-0.636 0.637,-0.621C0.64,-0.605 0.638,-0.591 0.631,-0.578C0.624,-0.565 0.613,-0.556 0.598,-0.552C0.583,-0.547 0.567,-0.551 0.549,-0.563C0.528,-0.576 0.506,-0.586 0.482,-0.592C0.458,-0.598 0.434,-0.601 0.409,-0.601C0.338,-0.601 0.283,-0.58 0.246,-0.537C0.209,-0.494 0.19,-0.432 0.19,-0.353C0.19,-0.274 0.209,-0.212 0.246,-0.169C0.283,-0.126 0.338,-0.104 0.409,-0.104C0.434,-0.104 0.459,-0.107 0.484,-0.114C0.509,-0.12 0.532,-0.13 0.554,-0.143C0.572,-0.154 0.588,-0.158 0.602,-0.154C0.616,-0.15 0.627,-0.142 0.634,-0.13C0.641,-0.117 0.643,-0.104 0.64,-0.089C0.637,-0.074 0.628,-0.061 0.613,-0.05C0.586,-0.031 0.555,-0.016 0.518,-0.006C0.481,0.005 0.443,0.01 0.405,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1037.99,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1049.28,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1061.47,1208.23)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1069.47,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1077.53,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1088.93,1208.23)">
                                <path d="M0.232,0.01C0.121,0.01 0.065,-0.052 0.065,-0.177L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.183C0.19,-0.123 0.215,-0.093 0.265,-0.093C0.27,-0.093 0.275,-0.093 0.28,-0.094C0.285,-0.094 0.289,-0.094 0.294,-0.095C0.303,-0.096 0.31,-0.094 0.313,-0.088C0.316,-0.081 0.318,-0.068 0.318,-0.049C0.318,-0.032 0.315,-0.019 0.308,-0.01C0.301,-0.001 0.29,0.005 0.275,0.007C0.261,0.009 0.247,0.01 0.232,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1229.07)">
                                <path d="M0.145,-0C0.1,-0 0.077,-0.023 0.077,-0.068L0.077,-0.637C0.077,-0.682 0.1,-0.705 0.145,-0.705L0.339,-0.705C0.457,-0.705 0.548,-0.675 0.613,-0.614C0.677,-0.553 0.709,-0.466 0.709,-0.353C0.709,-0.24 0.677,-0.153 0.613,-0.092C0.548,-0.031 0.457,-0 0.339,-0L0.145,-0ZM0.205,-0.106L0.331,-0.106C0.494,-0.106 0.575,-0.188 0.575,-0.353C0.575,-0.517 0.494,-0.599 0.331,-0.599L0.205,-0.599L0.205,-0.106Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1040.43,1229.07)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1045.74,1229.07)">
                                <path d="M0.244,0.01C0.215,0.01 0.185,0.007 0.153,0.001C0.121,-0.005 0.092,-0.015 0.067,-0.03C0.053,-0.038 0.044,-0.048 0.041,-0.06C0.038,-0.071 0.038,-0.082 0.043,-0.093C0.047,-0.103 0.054,-0.11 0.065,-0.115C0.075,-0.12 0.087,-0.119 0.101,-0.112C0.128,-0.099 0.153,-0.091 0.176,-0.086C0.199,-0.081 0.222,-0.078 0.245,-0.078C0.277,-0.078 0.301,-0.084 0.317,-0.095C0.332,-0.106 0.34,-0.12 0.34,-0.138C0.34,-0.166 0.319,-0.184 0.278,-0.192L0.178,-0.211C0.093,-0.227 0.051,-0.271 0.051,-0.343C0.051,-0.375 0.06,-0.403 0.078,-0.426C0.095,-0.449 0.12,-0.467 0.151,-0.48C0.182,-0.493 0.218,-0.499 0.259,-0.499C0.318,-0.499 0.37,-0.486 0.416,-0.46C0.429,-0.453 0.436,-0.444 0.439,-0.433C0.441,-0.421 0.44,-0.41 0.435,-0.4C0.43,-0.389 0.422,-0.382 0.411,-0.378C0.4,-0.373 0.388,-0.374 0.374,-0.381C0.353,-0.392 0.334,-0.399 0.315,-0.404C0.296,-0.409 0.277,-0.411 0.26,-0.411C0.227,-0.411 0.203,-0.405 0.188,-0.394C0.172,-0.383 0.164,-0.368 0.164,-0.349C0.164,-0.32 0.183,-0.302 0.221,-0.295L0.321,-0.276C0.364,-0.268 0.397,-0.253 0.42,-0.232C0.442,-0.211 0.453,-0.182 0.453,-0.146C0.453,-0.097 0.434,-0.059 0.396,-0.032C0.358,-0.004 0.307,0.01 0.244,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1055.8,1229.07)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1063.8,1229.07)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1071.97,1229.07)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1077.28,1229.07)">
                                <path d="M0.291,0.01C0.24,0.01 0.195,-0.001 0.157,-0.022C0.119,-0.043 0.09,-0.072 0.069,-0.111C0.048,-0.15 0.038,-0.195 0.038,-0.247C0.038,-0.299 0.048,-0.344 0.069,-0.382C0.09,-0.419 0.119,-0.448 0.157,-0.469C0.195,-0.489 0.24,-0.499 0.291,-0.499C0.313,-0.499 0.337,-0.496 0.363,-0.49C0.388,-0.484 0.412,-0.474 0.435,-0.46C0.448,-0.452 0.456,-0.442 0.459,-0.429C0.461,-0.416 0.459,-0.405 0.454,-0.394C0.448,-0.383 0.44,-0.376 0.429,-0.371C0.418,-0.366 0.405,-0.368 0.391,-0.376C0.36,-0.393 0.331,-0.402 0.302,-0.402C0.259,-0.402 0.225,-0.389 0.202,-0.362C0.178,-0.335 0.166,-0.296 0.166,-0.246C0.166,-0.197 0.178,-0.158 0.202,-0.13C0.225,-0.101 0.259,-0.087 0.302,-0.087C0.331,-0.087 0.36,-0.095 0.391,-0.112C0.405,-0.12 0.417,-0.122 0.428,-0.117C0.439,-0.112 0.446,-0.105 0.451,-0.094C0.456,-0.083 0.457,-0.071 0.454,-0.059C0.451,-0.046 0.444,-0.036 0.431,-0.028C0.409,-0.015 0.386,-0.005 0.361,0.001C0.336,0.007 0.313,0.01 0.291,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1087.16,1229.07)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,240.689,-14.159)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.416,0.01C0.338,0.01 0.272,-0.005 0.219,-0.034C0.165,-0.063 0.124,-0.105 0.096,-0.159C0.068,-0.213 0.054,-0.277 0.054,-0.351C0.054,-0.426 0.068,-0.49 0.097,-0.545C0.126,-0.599 0.167,-0.641 0.221,-0.671C0.275,-0.7 0.34,-0.715 0.415,-0.715C0.452,-0.715 0.49,-0.711 0.528,-0.702C0.565,-0.693 0.601,-0.677 0.634,-0.656C0.649,-0.647 0.657,-0.636 0.66,-0.622C0.662,-0.607 0.66,-0.594 0.653,-0.581C0.646,-0.568 0.636,-0.56 0.623,-0.555C0.61,-0.55 0.595,-0.552 0.579,-0.561C0.554,-0.576 0.528,-0.586 0.502,-0.593C0.476,-0.6 0.447,-0.603 0.415,-0.603C0.34,-0.603 0.284,-0.581 0.246,-0.538C0.207,-0.495 0.188,-0.432 0.188,-0.351C0.188,-0.269 0.208,-0.207 0.247,-0.164C0.286,-0.121 0.345,-0.1 0.422,-0.1C0.444,-0.1 0.466,-0.102 0.49,-0.106C0.513,-0.109 0.535,-0.115 0.558,-0.122L0.558,-0.286L0.448,-0.286C0.413,-0.286 0.396,-0.302 0.396,-0.334C0.396,-0.365 0.413,-0.381 0.448,-0.381L0.613,-0.381C0.648,-0.381 0.665,-0.364 0.665,-0.329L0.665,-0.086C0.665,-0.055 0.653,-0.036 0.629,-0.027C0.6,-0.016 0.566,-0.008 0.528,-0.001C0.49,0.007 0.453,0.01 0.416,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1039.89,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1047.89,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1059.18,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1070.47,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1229.07)">
                                <path d="M0.142,-0C0.099,-0 0.077,-0.022 0.077,-0.066L0.077,-0.643C0.077,-0.686 0.098,-0.708 0.141,-0.708C0.184,-0.708 0.205,-0.686 0.205,-0.643L0.205,-0.109L0.495,-0.109C0.532,-0.109 0.551,-0.091 0.551,-0.055C0.551,-0.018 0.532,-0 0.495,-0L0.142,-0Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1036.26,1229.07)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1047.66,1229.07)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.281L0.192,-0.281L0.357,-0.455C0.37,-0.468 0.382,-0.479 0.392,-0.486C0.402,-0.493 0.416,-0.497 0.433,-0.497C0.45,-0.497 0.464,-0.493 0.473,-0.484C0.482,-0.474 0.486,-0.464 0.486,-0.451C0.486,-0.438 0.48,-0.426 0.468,-0.413L0.318,-0.255L0.485,-0.075C0.497,-0.062 0.503,-0.049 0.502,-0.037C0.501,-0.024 0.495,-0.013 0.485,-0.005C0.475,0.004 0.462,0.008 0.447,0.008C0.428,0.008 0.413,0.004 0.403,-0.003C0.392,-0.01 0.379,-0.021 0.366,-0.036L0.192,-0.218L0.19,-0.218L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1058.61,1229.07)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,380.896,21.9495)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.141,0.008C0.098,0.008 0.077,-0.014 0.077,-0.058L0.077,-0.64C0.077,-0.683 0.099,-0.705 0.142,-0.705L0.383,-0.705C0.46,-0.705 0.52,-0.687 0.562,-0.65C0.604,-0.612 0.625,-0.561 0.625,-0.494C0.625,-0.441 0.611,-0.398 0.584,-0.364C0.557,-0.329 0.517,-0.306 0.466,-0.294C0.5,-0.283 0.528,-0.258 0.55,-0.217L0.625,-0.078C0.636,-0.057 0.639,-0.037 0.632,-0.019C0.624,-0.001 0.607,0.008 0.58,0.008C0.562,0.008 0.547,0.004 0.536,-0.004C0.525,-0.011 0.515,-0.023 0.506,-0.039L0.405,-0.225C0.393,-0.248 0.379,-0.263 0.363,-0.271C0.346,-0.278 0.325,-0.282 0.3,-0.282L0.205,-0.282L0.205,-0.058C0.205,-0.014 0.184,0.008 0.141,0.008ZM0.205,-0.377L0.361,-0.377C0.454,-0.377 0.501,-0.415 0.501,-0.491C0.501,-0.566 0.454,-0.604 0.361,-0.604L0.205,-0.604L0.205,-0.377Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1038.74,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1050.03,1208.23)">
                                <path d="M0.263,0.008C0.23,0.008 0.205,-0.01 0.189,-0.045L0.03,-0.414C0.021,-0.436 0.021,-0.455 0.03,-0.472C0.039,-0.489 0.057,-0.497 0.084,-0.497C0.099,-0.497 0.112,-0.494 0.121,-0.487C0.13,-0.48 0.139,-0.466 0.146,-0.447L0.266,-0.147L0.39,-0.448C0.397,-0.467 0.406,-0.48 0.416,-0.487C0.425,-0.494 0.438,-0.497 0.455,-0.497C0.476,-0.497 0.491,-0.489 0.5,-0.472C0.509,-0.455 0.508,-0.436 0.499,-0.415L0.337,-0.045C0.322,-0.01 0.298,0.008 0.263,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1060.8,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1072.09,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1084.28,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1096.47,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,461.293,-88.0004)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.142,-0C0.099,-0 0.077,-0.022 0.077,-0.066L0.077,-0.643C0.077,-0.686 0.098,-0.708 0.141,-0.708C0.184,-0.708 0.205,-0.686 0.205,-0.643L0.205,-0.109L0.495,-0.109C0.532,-0.109 0.551,-0.091 0.551,-0.055C0.551,-0.018 0.532,-0 0.495,-0L0.142,-0Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1036.26,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1047.66,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.65C0.065,-0.692 0.086,-0.713 0.127,-0.713C0.169,-0.713 0.19,-0.692 0.19,-0.65L0.19,-0.281L0.192,-0.281L0.357,-0.455C0.37,-0.468 0.382,-0.479 0.392,-0.486C0.402,-0.493 0.416,-0.497 0.433,-0.497C0.45,-0.497 0.464,-0.493 0.473,-0.484C0.482,-0.474 0.486,-0.464 0.486,-0.451C0.486,-0.438 0.48,-0.426 0.468,-0.413L0.318,-0.255L0.485,-0.075C0.497,-0.062 0.503,-0.049 0.502,-0.037C0.501,-0.024 0.495,-0.013 0.485,-0.005C0.475,0.004 0.462,0.008 0.447,0.008C0.428,0.008 0.413,0.004 0.403,-0.003C0.392,-0.01 0.379,-0.021 0.366,-0.036L0.192,-0.218L0.19,-0.218L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1058.61,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1229.07)">
                                <path d="M0.405,0.01C0.332,0.01 0.269,-0.005 0.217,-0.035C0.164,-0.064 0.124,-0.106 0.096,-0.161C0.068,-0.215 0.054,-0.279 0.054,-0.353C0.054,-0.427 0.068,-0.491 0.096,-0.545C0.124,-0.599 0.164,-0.641 0.217,-0.671C0.269,-0.7 0.332,-0.715 0.405,-0.715C0.442,-0.715 0.478,-0.71 0.513,-0.701C0.548,-0.692 0.579,-0.678 0.606,-0.661C0.623,-0.65 0.634,-0.636 0.637,-0.621C0.64,-0.605 0.638,-0.591 0.631,-0.578C0.624,-0.565 0.613,-0.556 0.598,-0.552C0.583,-0.547 0.567,-0.551 0.549,-0.563C0.528,-0.576 0.506,-0.586 0.482,-0.592C0.458,-0.598 0.434,-0.601 0.409,-0.601C0.338,-0.601 0.283,-0.58 0.246,-0.537C0.209,-0.494 0.19,-0.432 0.19,-0.353C0.19,-0.274 0.209,-0.212 0.246,-0.169C0.283,-0.126 0.338,-0.104 0.409,-0.104C0.434,-0.104 0.459,-0.107 0.484,-0.114C0.509,-0.12 0.532,-0.13 0.554,-0.143C0.572,-0.154 0.588,-0.158 0.602,-0.154C0.616,-0.15 0.627,-0.142 0.634,-0.13C0.641,-0.117 0.643,-0.104 0.64,-0.089C0.637,-0.074 0.628,-0.061 0.613,-0.05C0.586,-0.031 0.555,-0.016 0.518,-0.006C0.481,0.005 0.443,0.01 0.405,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1038.72,1229.07)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1044.03,1229.07)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1052.2,1229.07)">
                                <path d="M0.189,0.188C0.166,0.188 0.151,0.18 0.142,0.163C0.133,0.146 0.134,0.127 0.143,0.106L0.2,-0.02L0.029,-0.414C0.02,-0.436 0.02,-0.455 0.029,-0.472C0.038,-0.489 0.057,-0.497 0.084,-0.497C0.099,-0.497 0.112,-0.494 0.121,-0.487C0.13,-0.48 0.139,-0.466 0.146,-0.447L0.266,-0.147L0.389,-0.448C0.396,-0.467 0.405,-0.48 0.415,-0.487C0.425,-0.494 0.438,-0.497 0.455,-0.497C0.476,-0.497 0.491,-0.489 0.5,-0.472C0.509,-0.455 0.508,-0.436 0.499,-0.415L0.256,0.14C0.247,0.159 0.238,0.171 0.228,0.178C0.218,0.185 0.205,0.188 0.189,0.188Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,539.378,-7.63326)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.32,0.01C0.275,0.01 0.232,0.005 0.19,-0.005C0.147,-0.015 0.11,-0.03 0.079,-0.05C0.064,-0.06 0.054,-0.072 0.051,-0.087C0.048,-0.102 0.049,-0.116 0.055,-0.129C0.06,-0.141 0.069,-0.151 0.082,-0.156C0.094,-0.161 0.108,-0.158 0.124,-0.148C0.153,-0.131 0.184,-0.118 0.217,-0.11C0.25,-0.102 0.284,-0.098 0.32,-0.098C0.373,-0.098 0.411,-0.107 0.435,-0.125C0.459,-0.142 0.471,-0.165 0.471,-0.193C0.471,-0.216 0.463,-0.235 0.446,-0.248C0.428,-0.261 0.399,-0.272 0.358,-0.281L0.248,-0.304C0.123,-0.331 0.06,-0.395 0.06,-0.497C0.06,-0.541 0.072,-0.579 0.095,-0.612C0.118,-0.645 0.151,-0.67 0.192,-0.688C0.233,-0.706 0.281,-0.715 0.335,-0.715C0.375,-0.715 0.413,-0.71 0.449,-0.7C0.485,-0.69 0.517,-0.675 0.546,-0.656C0.559,-0.647 0.567,-0.635 0.57,-0.622C0.573,-0.608 0.571,-0.595 0.565,-0.584C0.559,-0.572 0.55,-0.564 0.537,-0.56C0.524,-0.555 0.508,-0.558 0.491,-0.568C0.466,-0.582 0.441,-0.592 0.416,-0.598C0.391,-0.604 0.363,-0.607 0.334,-0.607C0.288,-0.607 0.252,-0.597 0.226,-0.578C0.2,-0.559 0.187,-0.533 0.187,-0.502C0.187,-0.478 0.195,-0.459 0.211,-0.445C0.227,-0.43 0.254,-0.419 0.293,-0.411L0.402,-0.388C0.468,-0.374 0.517,-0.352 0.55,-0.322C0.582,-0.292 0.598,-0.252 0.598,-0.202C0.598,-0.16 0.587,-0.123 0.564,-0.091C0.541,-0.059 0.509,-0.034 0.468,-0.017C0.426,0.001 0.377,0.01 0.32,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1037.7,1208.23)">
                                <path d="M0.226,0.01C0.191,0.01 0.159,0.003 0.132,-0.011C0.104,-0.024 0.082,-0.043 0.067,-0.066C0.051,-0.089 0.043,-0.116 0.043,-0.145C0.043,-0.181 0.052,-0.21 0.071,-0.231C0.09,-0.252 0.12,-0.267 0.162,-0.276C0.204,-0.285 0.26,-0.29 0.331,-0.29L0.366,-0.29L0.366,-0.311C0.366,-0.344 0.359,-0.368 0.344,-0.383C0.329,-0.398 0.305,-0.405 0.27,-0.405C0.251,-0.405 0.23,-0.403 0.208,-0.398C0.185,-0.393 0.162,-0.385 0.137,-0.374C0.121,-0.367 0.108,-0.366 0.097,-0.372C0.086,-0.377 0.079,-0.386 0.076,-0.399C0.072,-0.411 0.073,-0.423 0.078,-0.436C0.083,-0.449 0.093,-0.458 0.108,-0.464C0.139,-0.477 0.168,-0.486 0.197,-0.491C0.225,-0.496 0.251,-0.499 0.274,-0.499C0.345,-0.499 0.398,-0.483 0.433,-0.45C0.468,-0.417 0.485,-0.365 0.485,-0.296L0.485,-0.056C0.485,-0.013 0.466,0.008 0.427,0.008C0.388,0.008 0.368,-0.013 0.368,-0.056L0.368,-0.078C0.357,-0.051 0.339,-0.029 0.314,-0.014C0.289,0.002 0.26,0.01 0.226,0.01ZM0.252,-0.076C0.285,-0.076 0.312,-0.087 0.334,-0.11C0.355,-0.133 0.366,-0.161 0.366,-0.196L0.366,-0.218L0.332,-0.218C0.269,-0.218 0.226,-0.213 0.202,-0.204C0.177,-0.194 0.165,-0.176 0.165,-0.151C0.165,-0.129 0.173,-0.111 0.188,-0.097C0.203,-0.083 0.225,-0.076 0.252,-0.076Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1049.09,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1061.28,1208.23)">
                                <path d="M0.253,0.01C0.21,0.01 0.173,-0 0.141,-0.021C0.108,-0.042 0.083,-0.071 0.065,-0.11C0.047,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.298 0.047,-0.343 0.065,-0.381C0.083,-0.418 0.108,-0.447 0.141,-0.468C0.173,-0.489 0.21,-0.499 0.253,-0.499C0.288,-0.499 0.319,-0.491 0.347,-0.476C0.375,-0.461 0.396,-0.44 0.41,-0.415L0.41,-0.65C0.41,-0.692 0.431,-0.713 0.472,-0.713C0.514,-0.713 0.535,-0.692 0.535,-0.65L0.535,-0.056C0.535,-0.013 0.514,0.008 0.473,0.008C0.432,0.008 0.411,-0.013 0.411,-0.056L0.411,-0.077C0.397,-0.05 0.376,-0.029 0.348,-0.014C0.32,0.002 0.288,0.01 0.253,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1229.07)">
                                <path d="M0.141,0.008C0.098,0.008 0.077,-0.014 0.077,-0.058L0.077,-0.64C0.077,-0.683 0.099,-0.705 0.142,-0.705L0.383,-0.705C0.46,-0.705 0.52,-0.686 0.562,-0.647C0.604,-0.608 0.625,-0.554 0.625,-0.485C0.625,-0.416 0.604,-0.362 0.562,-0.323C0.52,-0.284 0.46,-0.264 0.383,-0.264L0.205,-0.264L0.205,-0.058C0.205,-0.014 0.184,0.008 0.141,0.008ZM0.205,-0.365L0.363,-0.365C0.455,-0.365 0.501,-0.405 0.501,-0.485C0.501,-0.564 0.455,-0.604 0.363,-0.604L0.205,-0.604L0.205,-0.365Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1037.61,1229.07)">
                                <path d="M0.288,0.01C0.237,0.01 0.193,-0 0.156,-0.021C0.119,-0.042 0.09,-0.071 0.069,-0.11C0.048,-0.148 0.038,-0.193 0.038,-0.245C0.038,-0.297 0.048,-0.342 0.069,-0.38C0.09,-0.418 0.119,-0.447 0.156,-0.468C0.193,-0.489 0.237,-0.499 0.288,-0.499C0.339,-0.499 0.383,-0.489 0.42,-0.468C0.457,-0.447 0.486,-0.418 0.507,-0.38C0.528,-0.342 0.538,-0.297 0.538,-0.245C0.538,-0.193 0.528,-0.148 0.507,-0.11C0.486,-0.071 0.457,-0.042 0.42,-0.021C0.383,-0 0.339,0.01 0.288,0.01ZM0.288,-0.085C0.325,-0.085 0.355,-0.098 0.378,-0.126C0.401,-0.153 0.412,-0.192 0.412,-0.245C0.412,-0.298 0.401,-0.338 0.378,-0.365C0.355,-0.391 0.325,-0.404 0.288,-0.404C0.251,-0.404 0.221,-0.391 0.198,-0.365C0.175,-0.338 0.164,-0.298 0.164,-0.245C0.164,-0.192 0.175,-0.153 0.198,-0.126C0.221,-0.098 0.251,-0.085 0.288,-0.085Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1049.61,1229.07)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1054.93,1229.07)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1067.11,1229.07)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                        <g transform="matrix(1,0,0,1,324.406,120.667)">
                            <g transform="matrix(20.8333,0,0,20.8333,1024.55,1208.23)">
                                <path d="M0.37,0.01C0.273,0.01 0.2,-0.015 0.149,-0.066C0.097,-0.116 0.072,-0.19 0.072,-0.289L0.072,-0.648C0.072,-0.691 0.093,-0.713 0.136,-0.713C0.179,-0.713 0.2,-0.691 0.2,-0.648L0.2,-0.29C0.2,-0.227 0.215,-0.179 0.244,-0.148C0.273,-0.116 0.315,-0.1 0.37,-0.1C0.483,-0.1 0.539,-0.163 0.539,-0.29L0.539,-0.648C0.539,-0.691 0.56,-0.713 0.603,-0.713C0.645,-0.713 0.666,-0.691 0.666,-0.648L0.666,-0.289C0.666,-0.19 0.641,-0.116 0.591,-0.066C0.541,-0.015 0.467,0.01 0.37,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1039.93,1208.23)">
                                <path d="M0.127,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.413C0.204,-0.441 0.226,-0.462 0.255,-0.477C0.283,-0.492 0.315,-0.499 0.35,-0.499C0.465,-0.499 0.523,-0.432 0.523,-0.298L0.523,-0.056C0.523,-0.013 0.502,0.008 0.461,0.008C0.419,0.008 0.398,-0.013 0.398,-0.056L0.398,-0.292C0.398,-0.33 0.391,-0.358 0.377,-0.375C0.362,-0.392 0.34,-0.401 0.31,-0.401C0.273,-0.401 0.244,-0.39 0.223,-0.367C0.201,-0.343 0.19,-0.313 0.19,-0.275L0.19,-0.056C0.19,-0.013 0.169,0.008 0.127,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1052.11,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1057.43,1208.23)">
                                <path d="M0.263,0.008C0.23,0.008 0.205,-0.01 0.189,-0.045L0.03,-0.414C0.021,-0.436 0.021,-0.455 0.03,-0.472C0.039,-0.489 0.057,-0.497 0.084,-0.497C0.099,-0.497 0.112,-0.494 0.121,-0.487C0.13,-0.48 0.139,-0.466 0.146,-0.447L0.266,-0.147L0.39,-0.448C0.397,-0.467 0.406,-0.48 0.416,-0.487C0.425,-0.494 0.438,-0.497 0.455,-0.497C0.476,-0.497 0.491,-0.489 0.5,-0.472C0.509,-0.455 0.508,-0.436 0.499,-0.415L0.337,-0.045C0.322,-0.01 0.298,0.008 0.263,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1068.2,1208.23)">
                                <path d="M0.306,0.01C0.251,0.01 0.203,-0 0.163,-0.021C0.123,-0.042 0.092,-0.071 0.071,-0.109C0.049,-0.147 0.038,-0.192 0.038,-0.244C0.038,-0.295 0.049,-0.339 0.07,-0.377C0.091,-0.415 0.12,-0.445 0.157,-0.467C0.194,-0.488 0.236,-0.499 0.283,-0.499C0.352,-0.499 0.407,-0.477 0.448,-0.433C0.488,-0.389 0.508,-0.329 0.508,-0.253C0.508,-0.228 0.492,-0.216 0.46,-0.216L0.158,-0.216C0.167,-0.129 0.217,-0.085 0.308,-0.085C0.325,-0.085 0.345,-0.087 0.367,-0.092C0.388,-0.096 0.409,-0.103 0.428,-0.114C0.445,-0.124 0.46,-0.127 0.472,-0.124C0.484,-0.12 0.493,-0.113 0.498,-0.102C0.503,-0.091 0.503,-0.08 0.5,-0.067C0.496,-0.054 0.486,-0.042 0.471,-0.033C0.448,-0.018 0.421,-0.008 0.391,-0.001C0.36,0.007 0.332,0.01 0.306,0.01ZM0.288,-0.414C0.251,-0.414 0.222,-0.403 0.199,-0.38C0.176,-0.357 0.163,-0.327 0.158,-0.288L0.404,-0.288C0.401,-0.329 0.39,-0.36 0.371,-0.382C0.351,-0.403 0.323,-0.414 0.288,-0.414Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1079.49,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1087.55,1208.23)">
                                <path d="M0.244,0.01C0.215,0.01 0.185,0.007 0.153,0.001C0.121,-0.005 0.092,-0.015 0.067,-0.03C0.053,-0.038 0.044,-0.048 0.041,-0.06C0.038,-0.071 0.038,-0.082 0.043,-0.093C0.047,-0.103 0.054,-0.11 0.065,-0.115C0.075,-0.12 0.087,-0.119 0.101,-0.112C0.128,-0.099 0.153,-0.091 0.176,-0.086C0.199,-0.081 0.222,-0.078 0.245,-0.078C0.277,-0.078 0.301,-0.084 0.317,-0.095C0.332,-0.106 0.34,-0.12 0.34,-0.138C0.34,-0.166 0.319,-0.184 0.278,-0.192L0.178,-0.211C0.093,-0.227 0.051,-0.271 0.051,-0.343C0.051,-0.375 0.06,-0.403 0.078,-0.426C0.095,-0.449 0.12,-0.467 0.151,-0.48C0.182,-0.493 0.218,-0.499 0.259,-0.499C0.318,-0.499 0.37,-0.486 0.416,-0.46C0.429,-0.453 0.436,-0.444 0.439,-0.433C0.441,-0.421 0.44,-0.41 0.435,-0.4C0.43,-0.389 0.422,-0.382 0.411,-0.378C0.4,-0.373 0.388,-0.374 0.374,-0.381C0.353,-0.392 0.334,-0.399 0.315,-0.404C0.296,-0.409 0.277,-0.411 0.26,-0.411C0.227,-0.411 0.203,-0.405 0.188,-0.394C0.172,-0.383 0.164,-0.368 0.164,-0.349C0.164,-0.32 0.183,-0.302 0.221,-0.295L0.321,-0.276C0.364,-0.268 0.397,-0.253 0.42,-0.232C0.442,-0.211 0.453,-0.182 0.453,-0.146C0.453,-0.097 0.434,-0.059 0.396,-0.032C0.358,-0.004 0.307,0.01 0.244,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1097.72,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1103.03,1208.23)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1111.2,1208.23)">
                                <path d="M0.189,0.188C0.166,0.188 0.151,0.18 0.142,0.163C0.133,0.146 0.134,0.127 0.143,0.106L0.2,-0.02L0.029,-0.414C0.02,-0.436 0.02,-0.455 0.029,-0.472C0.038,-0.489 0.057,-0.497 0.084,-0.497C0.099,-0.497 0.112,-0.494 0.121,-0.487C0.13,-0.48 0.139,-0.466 0.146,-0.447L0.266,-0.147L0.389,-0.448C0.396,-0.467 0.405,-0.48 0.415,-0.487C0.425,-0.494 0.438,-0.497 0.455,-0.497C0.476,-0.497 0.491,-0.489 0.5,-0.472C0.509,-0.455 0.508,-0.436 0.499,-0.415L0.256,0.14C0.247,0.159 0.238,0.171 0.228,0.178C0.218,0.185 0.205,0.188 0.189,0.188Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1127.8,1208.23)">
                                <path d="M0.145,-0C0.1,-0 0.077,-0.023 0.077,-0.068L0.077,-0.637C0.077,-0.682 0.1,-0.705 0.145,-0.705L0.339,-0.705C0.457,-0.705 0.548,-0.675 0.613,-0.614C0.677,-0.553 0.709,-0.466 0.709,-0.353C0.709,-0.24 0.677,-0.153 0.613,-0.092C0.548,-0.031 0.457,-0 0.339,-0L0.145,-0ZM0.205,-0.106L0.331,-0.106C0.494,-0.106 0.575,-0.188 0.575,-0.353C0.575,-0.517 0.494,-0.599 0.331,-0.599L0.205,-0.599L0.205,-0.106Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1143.68,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1148.99,1208.23)">
                                <path d="M0.244,0.01C0.215,0.01 0.185,0.007 0.153,0.001C0.121,-0.005 0.092,-0.015 0.067,-0.03C0.053,-0.038 0.044,-0.048 0.041,-0.06C0.038,-0.071 0.038,-0.082 0.043,-0.093C0.047,-0.103 0.054,-0.11 0.065,-0.115C0.075,-0.12 0.087,-0.119 0.101,-0.112C0.128,-0.099 0.153,-0.091 0.176,-0.086C0.199,-0.081 0.222,-0.078 0.245,-0.078C0.277,-0.078 0.301,-0.084 0.317,-0.095C0.332,-0.106 0.34,-0.12 0.34,-0.138C0.34,-0.166 0.319,-0.184 0.278,-0.192L0.178,-0.211C0.093,-0.227 0.051,-0.271 0.051,-0.343C0.051,-0.375 0.06,-0.403 0.078,-0.426C0.095,-0.449 0.12,-0.467 0.151,-0.48C0.182,-0.493 0.218,-0.499 0.259,-0.499C0.318,-0.499 0.37,-0.486 0.416,-0.46C0.429,-0.453 0.436,-0.444 0.439,-0.433C0.441,-0.421 0.44,-0.41 0.435,-0.4C0.43,-0.389 0.422,-0.382 0.411,-0.378C0.4,-0.373 0.388,-0.374 0.374,-0.381C0.353,-0.392 0.334,-0.399 0.315,-0.404C0.296,-0.409 0.277,-0.411 0.26,-0.411C0.227,-0.411 0.203,-0.405 0.188,-0.394C0.172,-0.383 0.164,-0.368 0.164,-0.349C0.164,-0.32 0.183,-0.302 0.221,-0.295L0.321,-0.276C0.364,-0.268 0.397,-0.253 0.42,-0.232C0.442,-0.211 0.453,-0.182 0.453,-0.146C0.453,-0.097 0.434,-0.059 0.396,-0.032C0.358,-0.004 0.307,0.01 0.244,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1159.05,1208.23)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1167.05,1208.23)">
                                <path d="M0.129,0.008C0.086,0.008 0.065,-0.013 0.065,-0.056L0.065,-0.434C0.065,-0.476 0.085,-0.497 0.126,-0.497C0.167,-0.497 0.187,-0.476 0.187,-0.434L0.187,-0.403C0.211,-0.46 0.262,-0.492 0.341,-0.499C0.37,-0.502 0.386,-0.486 0.389,-0.45C0.392,-0.415 0.375,-0.395 0.337,-0.391L0.315,-0.389C0.233,-0.381 0.192,-0.339 0.192,-0.263L0.192,-0.056C0.192,-0.013 0.171,0.008 0.129,0.008Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1175.22,1208.23)">
                                <path d="M0.127,-0.589C0.104,-0.589 0.086,-0.595 0.073,-0.607C0.059,-0.618 0.053,-0.634 0.053,-0.655C0.053,-0.676 0.059,-0.693 0.073,-0.704C0.086,-0.715 0.104,-0.721 0.127,-0.721C0.176,-0.721 0.201,-0.699 0.201,-0.655C0.201,-0.611 0.176,-0.589 0.127,-0.589ZM0.127,0.007C0.107,0.007 0.092,0.001 0.081,-0.011C0.07,-0.023 0.065,-0.04 0.065,-0.061L0.065,-0.428C0.065,-0.473 0.086,-0.496 0.127,-0.496C0.169,-0.496 0.19,-0.473 0.19,-0.428L0.19,-0.061C0.19,-0.04 0.185,-0.023 0.174,-0.011C0.163,0.001 0.148,0.007 0.127,0.007Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1180.53,1208.23)">
                                <path d="M0.291,0.01C0.24,0.01 0.195,-0.001 0.157,-0.022C0.119,-0.043 0.09,-0.072 0.069,-0.111C0.048,-0.15 0.038,-0.195 0.038,-0.247C0.038,-0.299 0.048,-0.344 0.069,-0.382C0.09,-0.419 0.119,-0.448 0.157,-0.469C0.195,-0.489 0.24,-0.499 0.291,-0.499C0.313,-0.499 0.337,-0.496 0.363,-0.49C0.388,-0.484 0.412,-0.474 0.435,-0.46C0.448,-0.452 0.456,-0.442 0.459,-0.429C0.461,-0.416 0.459,-0.405 0.454,-0.394C0.448,-0.383 0.44,-0.376 0.429,-0.371C0.418,-0.366 0.405,-0.368 0.391,-0.376C0.36,-0.393 0.331,-0.402 0.302,-0.402C0.259,-0.402 0.225,-0.389 0.202,-0.362C0.178,-0.335 0.166,-0.296 0.166,-0.246C0.166,-0.197 0.178,-0.158 0.202,-0.13C0.225,-0.101 0.259,-0.087 0.302,-0.087C0.331,-0.087 0.36,-0.095 0.391,-0.112C0.405,-0.12 0.417,-0.122 0.428,-0.117C0.439,-0.112 0.446,-0.105 0.451,-0.094C0.456,-0.083 0.457,-0.071 0.454,-0.059C0.451,-0.046 0.444,-0.036 0.431,-0.028C0.409,-0.015 0.386,-0.005 0.361,0.001C0.336,0.007 0.313,0.01 0.291,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                            <g transform="matrix(20.8333,0,0,20.8333,1190.41,1208.23)">
                                <path d="M0.289,0.01C0.16,0.01 0.095,-0.054 0.095,-0.182L0.095,-0.395L0.043,-0.395C0.01,-0.395 -0.007,-0.411 -0.007,-0.442C-0.007,-0.473 0.01,-0.489 0.043,-0.489L0.095,-0.489L0.095,-0.58C0.095,-0.622 0.116,-0.643 0.158,-0.643C0.199,-0.643 0.22,-0.622 0.22,-0.58L0.22,-0.489L0.326,-0.489C0.359,-0.489 0.376,-0.473 0.376,-0.442C0.376,-0.411 0.359,-0.395 0.326,-0.395L0.22,-0.395L0.22,-0.189C0.22,-0.157 0.227,-0.133 0.241,-0.117C0.255,-0.101 0.278,-0.093 0.309,-0.093C0.32,-0.093 0.33,-0.094 0.339,-0.096C0.348,-0.098 0.355,-0.099 0.362,-0.1C0.37,-0.101 0.377,-0.098 0.382,-0.093C0.387,-0.087 0.39,-0.075 0.39,-0.058C0.39,-0.045 0.388,-0.033 0.384,-0.023C0.379,-0.012 0.371,-0.005 0.36,-0.001C0.351,0.002 0.34,0.004 0.326,0.007C0.312,0.009 0.3,0.01 0.289,0.01Z" fill="white" fill-rule="nonzero"/>
                            </g>
                        </g>
                    </g>
                    <g id="extras">
                        <g id="crown" transform="matrix(0.246626,1.34559e-17,-2.38785e-17,0.362797,809.859,964.597)">
                            <path d="M1681.14,1574.45L1731.75,1574.45L1734.75,1548.33L1720.82,1561.32L1714.79,1549.55L1705.3,1561.1L1693.42,1549.37L1690.04,1562.33L1674.88,1548.56L1681.14,1574.45Z" fill="rgb(255,223,133)"/>
                        </g>
                        <g transform="matrix(0.246626,1.34559e-17,-2.38785e-17,0.362797,809.859,964.597)">
                            <path d="M1677.81,1574.83C1678.08,1575.94 1679.49,1576.75 1681.14,1576.75L1731.75,1576.75C1733.51,1576.75 1734.98,1575.83 1735.12,1574.63L1738.12,1548.51C1738.24,1547.49 1737.34,1546.53 1735.92,1546.18C1734.5,1545.82 1732.91,1546.15 1732.02,1546.98L1721.89,1556.42C1721.89,1556.42 1717.98,1548.79 1717.98,1548.79C1717.55,1547.94 1716.41,1547.34 1715.09,1547.26C1713.76,1547.18 1712.49,1547.64 1711.84,1548.43L1705,1556.75C1705,1556.75 1696.21,1548.07 1696.21,1548.07C1695.43,1547.3 1694.05,1546.92 1692.71,1547.12C1691.37,1547.32 1690.34,1548.05 1690.1,1548.97L1688.07,1556.72C1688.07,1556.72 1677.58,1547.19 1677.58,1547.19C1676.65,1546.34 1674.99,1546.04 1673.55,1546.45C1672.12,1546.87 1671.29,1547.89 1671.54,1548.94L1677.81,1574.83ZM1684.01,1572.16L1680.46,1557.46C1680.46,1557.46 1687.33,1563.71 1687.33,1563.71C1688.14,1564.44 1689.51,1564.78 1690.82,1564.57C1692.13,1564.36 1693.13,1563.64 1693.36,1562.73L1695.31,1555.28C1695.31,1555.28 1702.51,1562.4 1702.51,1562.4C1703.17,1563.06 1704.29,1563.43 1705.45,1563.39C1706.62,1563.36 1707.68,1562.91 1708.25,1562.22L1714.07,1555.13C1714.07,1555.13 1717.63,1562.08 1717.63,1562.08C1718.05,1562.88 1719.08,1563.47 1720.33,1563.59C1721.57,1563.72 1722.81,1563.37 1723.56,1562.67L1730.45,1556.24C1730.45,1556.24 1728.62,1572.16 1728.62,1572.16L1684.01,1572.16Z" fill="rgb(51,51,51)"/>
                        </g>
                        <g id="waves">
                            <g>
                                <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                <g transform="matrix(1,0,0,1,25,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-25,0)">
                                    <path d="M936.69,1574.04C940.203,1573.87 944.135,1572.38 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,50,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,75,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 928.886,1574.27 936.46,1574.05" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                            </g>
                            <g transform="matrix(1,0,0,1,75,0)">
                                <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                <g transform="matrix(1,0,0,1,25,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-25,0)">
                                    <path d="M936.69,1574.04C940.203,1573.87 944.135,1572.38 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,50,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,75,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 928.886,1574.27 936.46,1574.05" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                            </g>
                            <g transform="matrix(1,0,0,1,177.322,77.7941)">
                                <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                <g transform="matrix(1,0,0,1,25,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-25,0)">
                                    <path d="M936.69,1574.04C940.203,1573.87 944.135,1572.38 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,50,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,75,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 928.886,1574.27 936.46,1574.05" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                            </g>
                            <g transform="matrix(1,0,0,1,629.442,-146.097)">
                                <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                <g transform="matrix(1,0,0,1,25,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-25,0)">
                                    <path d="M936.69,1574.04C940.203,1573.87 944.135,1572.38 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,50,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,75,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 928.886,1574.27 936.46,1574.05" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                            </g>
                            <g transform="matrix(1,0,0,1,638.297,82.5368)">
                                <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                <g transform="matrix(1,0,0,1,25,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-25,0)">
                                    <path d="M936.69,1574.04C940.203,1573.87 944.135,1572.38 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,50,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 928.959,1574.35 936.607,1574.04" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                            </g>
                            <g transform="matrix(1,0,0,1,-44.277,-391.011)">
                                <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                <g transform="matrix(1,0,0,1,25,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,-25,0)">
                                    <path d="M936.69,1574.04C940.203,1573.87 944.135,1572.38 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,50,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 935.379,1581.14 948.134,1568.38" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                                <g transform="matrix(1,0,0,1,75,0)">
                                    <path d="M923.315,1568.38C923.315,1568.38 928.886,1574.27 936.46,1574.05" fill="none" stroke="rgb(50,128,171)" stroke-width="4.17px"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>  
);  